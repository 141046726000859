import React, { useEffect, useState } from "react";
import PrimaryButton from "../buttons/button";
import { Dialog } from "primereact/dialog";
import CustomUploadFile from "../UploadFile/CustomUploadFile";
import { CustomFloatPassword } from "../input/input";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction } from "../../redux1/Slices/registerSlice";
import { isAuthenticated } from "../../services/auth";
import { useNavigate } from "react-router-dom";

const ChangePassword = ({ visible, setVisible }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    oldPassword: "",
    password: "",
    oldPassword: "",
  });

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleResetPassword = () => {
    if (showFormErrors(data, setData)) {
      setLoading(true);
      const payload = {
        token: isAuthenticated(),
        oldPassword: data.oldPassword,
        newPassword: data.password,
      };
      dispatch(
        ResetPasswordAction({ data: payload, navigate, setVisible, setLoading })
      );
    }
  };

  return (
    <>
      <Dialog
        visible={visible}
        modal
        style={{ width: "50rem" }}
        className="border-round-lg"
        onHide={() => setVisible(false)}
        content={({ hide }) => (
          <div className="flex justify-content-center border-round-lg inter">
            <div className="signup md:col-6 lg:col-11 p-4 col-11">
              <div className="text-center pb-2 pb-4">
                <span className="kagitingan font-bold lg:text-4xl text-lg ">
                  Change Password
                </span>
              </div>
              <div className="grid">
                <CustomFloatPassword
                  name="oldPassword"
                  data={data}
                  onChange={handleChange}
                  col={12}
                  label={"Old Password"}
                  extraClassName={"mb-2"}
                ></CustomFloatPassword>
                <CustomFloatPassword
                  name="password"
                  data={data}
                  onChange={handleChange}
                  col={12}
                  label={"New Password"}
                  extraClassName={"mb-2"}
                ></CustomFloatPassword>
                <CustomFloatPassword
                  name="confirmPassword"
                  data={data}
                  onChange={handleChange}
                  extraClassName={"mb-2"}
                  col={12}
                  label={"Confirm New Password"}
                ></CustomFloatPassword>{" "}
              </div>
              <div className="center align-items-center gap-3 ">
                <PrimaryButton
                  col={3}
                  extraClass={""}
                  label={"Save"}
                  onClick={handleResetPassword}
                  loading={loading}
                ></PrimaryButton>
                <PrimaryButton
                  col={3}
                  secondary
                  extraClass={""}
                  label="cancel"
                  onClick={() => setVisible(false)}
                ></PrimaryButton>
              </div>
            </div>
          </div>
        )}
      ></Dialog>
    </>
  );
};

export default ChangePassword;
