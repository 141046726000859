import { configureStore } from "@reduxjs/toolkit";
import loaderReducer from "./Slices/loaderSlice";
import toastReducer from "./Slices/toastSlice";
import userReducer from "./Slices/loginSlice";
import { thunk } from "redux-thunk";
import parentReducer from "./Slices/parentSlice";
import EventsReducer from "./Slices/eventSlice";
import radRunReducer from './Slices/radRunSlice';
import registerReducer from './Slices/registerSlice';

const store = configureStore({
  reducer: {
    loader: loaderReducer,
    toast: toastReducer,
    user: userReducer,
    children: parentReducer,
    events: EventsReducer,
    radRun: radRunReducer,
    register: registerReducer,
  },
  middleware: () => [thunk],
});

export default store;
