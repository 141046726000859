import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CustomSignInCard from "../../components/cards/CustomSignInCard";
import Topbar from "../../layout/Topbar";
import { useDispatch } from "react-redux";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import { loginAction } from "../../redux1/Slices/loginSlice";
import { isAuthenticated } from "../../services/auth";
import { forgotPassword, verifyOTP } from "../../redux1/Slices/registerSlice";
import CustomOtpCard from "../../components/cards/CustomOtpCard";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [success, setSuccess] = useState(false);
  const [ShowOtp, setShowOtp] = useState(false);
  const [ShowEmail, setEmail] = useState(false);
  const [userRole, setUserRole] = useState("");
  const { state } = useLocation();
  const [data, setData] = useState({
    email: state?.email,
  });

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleResetPassword = () => {
    if (showFormErrors(data, setData)) {
      dispatch(forgotPassword({ data, setShowOtp })).then((value) => {
        setEmail(value.payload);
      });
    }
  };

  const handleVerify = () => {
    if (showFormErrors(otp, setOtp)) {
      const payload = {
        otp: otp,
        email: ShowEmail,
        otpType: 2,
      };
      dispatch(verifyOTP({ payload, navigate })).then((value) => {
        setUserRole(value.payload);
      });
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <>
      <Topbar />
      <div className="background-image">
        <div className="flex justify-content-center align-items-center w-full h-100-top">
          {ShowOtp ? (
            <CustomOtpCard
              heading={"OTP VERIFICATION"}
              onClick={handleVerify}
              state={state}
              setOtp={setOtp}
              email={data.email}
              ShowEmail={ShowEmail}
              setShowOtp={setShowOtp}
              otp={otp}
            />
          ) : (
            <CustomSignInCard
              handleChange={handleChange}
              eventorganizer
              forgotPassword
              data={data}
              setData={setData}
              buttonLabel={"SEND OTP"}
              heading={"FORGOT PASSWORD"}
              SubHeading={
                "Please enter the email address linked with your account."
              }
              checkbox
              submit={handleResetPassword}
            ></CustomSignInCard>
          )}{" "}
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
