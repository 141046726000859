import React, { useEffect, useState } from "react";
import CustomTable from "../../components/table/table";
import { ReactComponent as EmptyEvents } from "../../assets/svg/EmptyEvent.svg";
import ContentCard from "../../components/cards/ContentCard";
import Topbar from "../../layout/Topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClubsAndSChools,
  patchClubModalDdata,
  updateClubAction,
} from "../../redux1/Slices/parentSlice";
import { useDebounce } from "primereact/hooks";
import { TableColumns } from "./TableColumns";
import { showAddSchoolModal } from "../../redux1/Slices/registerSlice";
import { useNavigate } from "react-router-dom";

const Clubs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [dialog, setDialog] = useState({ visible: false });
  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);

  const clubsAndSchools = useSelector(
    (state) => state.children.clubsAndSchools
  );

  const searchKeys = JSON.stringify(["name"]);
  const { clubColumns } = TableColumns();

  const [filterData, setFilterData] = useState({
    searchKeys,
    searchString: "",
  });

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <>
          <EmptyEvents />
          <h2 className="inter text-sm">No entities found.</h2>{" "}
        </>
      </div>
    );
  };

  // FETCH ALL CLUBS
  useEffect(() => {
    dispatch(fetchClubsAndSChools({ filterData }));
  }, [filterData]);

  const deleteClub = (id) => {
    setDialog({
      visible: true,
      title:
        "Are you sure you want to delete this entry? This action cannot be undone.",
      type: "DELETE",
      cancel: true,
      saveFunction: () => {
        const payload = {
          id,
          isDeleted: true,
        };
        dispatch(
          updateClubAction({
            data: payload,
            next: () => {
              setDialog(false);
            },
          })
        );
      },
    });
  };

  return (
    <>
      <Topbar />
      <div className="content-box  lg:py-4 p-3  ">
        <div className="shadow-2 border-round-xl">
          <div className="content-box border-round-xl px-3">
            <h1 className={"kagitingan my-0 lg:text-5xl text-xl pt-3"}>
              Schools/Clubs
            </h1>
            <ContentCard
              inputSearch={inputSearch}
              setSearch={setSearchValue}
              setDialog={setDialog}
              setVisible={setVisible}
              filterData={filterData}
              setFilterData={setFilterData}
              dialog={dialog}
              placeholder={"by School/Club Name"}
              onClick={() => {
                setVisible(true);
              }}
              heading={""}
            >
              <div className="px-3" style={{ minHeight: "30rem" }}>
                <CustomTable
                  emptyMessage={emptyMessage}
                  columns={clubColumns}
                  data={clubsAndSchools}
                  edit={(data) => {
                    dispatch(
                      patchClubModalDdata({ data: { ...data, id: data._id } })
                    );
                    dispatch(showAddSchoolModal());
                  }}
                  view={(row) => navigate(`/club/${row._id}`)}
                  trash={(id) => deleteClub(id)}
                />
              </div>
            </ContentCard>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clubs;
