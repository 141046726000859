import React, { useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import MainLogo from "../assets/images/Logo.jpeg";
import { isAuthenticated } from "../services/auth";
import { profileAction } from "../redux1/Slices/loginSlice";
import { useDispatch, useSelector } from "react-redux";
import { Sidebar } from "primereact/sidebar";
import { Button } from "primereact/button";
import { PanelMenu } from "primereact/panelmenu";
import TopbarLinks from "./TopbarLinks";

export default function Topbar() {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const { afterLoginLinks, beforeLoginLinks } = TopbarLinks();
  const user = useSelector((state) => state?.user?.data);
  const isLoading = useSelector((state) => state.loader.isDataLoading);
  const items = Array.from({ length: 3 }, (v, i) => i);

  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(profileAction({}));
    }
  }, []);
  return (
    <>
      <div className="bg-black relative lg:block hidden">
        <div className="flex align-items-center justify-content-around topbar-container lg-p-0 content-box relative">
          <img src={MainLogo} alt="" style={{ width: "112px" }} />
          <Menubar
            className="w-12 flex justify-content-end mobile-responsive"
            model={
              isLoading ? items : !user ? beforeLoginLinks : afterLoginLinks
            }
          />
        </div>
      </div>
      <div className="lg:hidden block bg-black relative flex justify-content-between p-2">
        <img src={MainLogo} alt="" style={{ width: "112px" }} />
        <Sidebar
          visible={visible}
          onHide={() => setVisible(false)}
          className="w-full"
          content={({ closeIconRef, hide }) => (
            <div className="">
              <div className="bg-black mb-1">
                <div className="center justify-content-between align-items-center p-2">
                  <img src={MainLogo} alt="" style={{ width: "112px" }} />
                  <span>
                    <Button
                      type="button"
                      ref={closeIconRef}
                      onClick={(e) => hide(e)}
                      icon="pi pi-times"
                      rounded
                      outlined
                      className="h-2rem w-2rem "
                    ></Button>
                  </span>
                </div>
              </div>
              <PanelMenu
                model={!user ? beforeLoginLinks : afterLoginLinks}
                className="w-full md:w-20rem p-2"
              />
            </div>
          )}
        ></Sidebar>
        <Button
          className="bg-black text-white border-none hover:bg-black"
          icon="pi pi-align-justify"
          onClick={() => setVisible(true)}
        />
      </div>{" "}
    </>
  );
}
