import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as ProfileCircle } from "../assets/svg/profile-circle.svg";
import { logout } from "../services/auth";
import { setUserDetail } from "../redux1/Slices/loginSlice";
import { showAddSchoolModal } from "../redux1/Slices/registerSlice";
import Constants from "../services/constants";

const TopbarLinks = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data);
  const dispatch = useDispatch();

  const itemRenderer = () => (
    <div
      className=" cursor-pointer hover:text-pink-400  cursor-pointer lg:text-white text-900 flex align-items-center lg:ml-6 ml-3 lg-hidden lg-mb-0 mb-3  gap-2"
      style={{ marginTop: "9px" }}
    >
      <small
        className={` text-xl kagitingan ${
          location.pathname === "/login" ? "pink" : ""
        }`}
        onClick={() => navigate("/login")}
      >
        Sign In
      </small>
      <ProfileCircle />
    </div>
  );

  const beforeLoginLinks = [
    {
      label: "Home",
      url: Constants.WEBSITE_URL,
      className: location.pathname === "radrun.club" ? "activeLink" : "",
    },
    {
      label: "Register",
      command: () => navigate("/register"),
      className: location.pathname === "/register" ? "activeLink" : "",
    },

    {
      label: "Events",
      // command: () => navigate("/events"),
      className: location.pathname === "/events" ? "activeLink" : "",
      items: [
        {
          label: "Find your nearest",
          command: () => navigate("/events"),
        },
        {
          label: "Start a RAD RUN",
          command: () => navigate("/start-radrun"),
        },
        {
          label: "Latest Results",
          command: () => navigate("/latest-events"),
        },
        {
          label: "Stats",
          command: () => navigate("/stats"),
        },
      ],
    },
    {
      label: "About",
      className: location.pathname === "/about" ? "activeLink" : "",
      items: [
        {
          label: "Who ?",
          command: () =>
            window.open("https://radrun.appdeft.in/who/", "_blank"),
        },
        {
          label: "Why ?",
          command: () =>
            window.open("https://radrun.appdeft.in/why/", "_blank"),
        },
        {
          label: "Partners ?",
          command: () =>
            window.open("https://radrun.appdeft.in/partners/", "_blank"),
        },
      ],
    },
    {
      label: "Blog",
      url: "https://radrun.appdeft.in/blog/",
      className: location.pathname === "/about" ? "activeLink" : "",
    },
    {
      label: "Profile",
      template: itemRenderer,
    },
  ];
  const afterLoginLinks = [
    // {
    //   label: "Home",
    //   url: "https://radrun.appdeft.in/blog/",
    //   // className: location.pathname === "/dashboard" ? "activeLink" : "",
    // },

    {
      label: "Events",
      // command: () => navigate("/events"),
      className: location.pathname === "/events" ? "activeLink" : "",
      items: [
        {
          label: "My Events",
          command: () => navigate("/my-events"),
        },
        {
          label: "Find your nearest",
          command: () => navigate("/events"),
        },
        {
          label: "Start a RAD RUN",
          command: () => navigate("/start-radrun"),
        },
        {
          label: "Latest Results",
          command: () => navigate("/latest-events"),
        },
        {
          label: "Stats",
          command: () => navigate("/stats"),
        },
      ],
    },
    // {
    //   label: "About",
    //   className: location.pathname === "/about" ? "activeLink" : "",

    //   items: [
    //     {
    //       label: "Who ?",
    //     },
    //     {
    //       label: "Why ?",
    //     },
    //     {
    //       label: "Partners ?",
    //     },
    //   ],
    // },
    // {
    //   label: "Blog",
    //   url: "https://radrun.appdeft.in/blog/",

    //   className: location.pathname === "/about" ? "activeLink" : "",
    // },
    {
      label: user?.firstName,
      items: [
        {
          label: "Add School/Club",
          command: () => dispatch(showAddSchoolModal()),
        },
        {
          label: "Manage Schools/Clubs",
          command: () => navigate("/clubs"),
        },
        {
          label: "Manage Child",
          command: () => navigate("/dashboard"),
          className: location.pathname === "/about" ? "activeLink" : "",
        },
        {
          label: "Edit Profile",
          command: () => navigate("/edit_profile"),
        },
        {
          label: "Logout",
          command: () =>
            logout(() => {
              navigate("/login");
              dispatch(setUserDetail(""));
            }),
        },
      ],
    },
  ];

  return { beforeLoginLinks, afterLoginLinks };
};

export default TopbarLinks;
