import React from "react";
import PrimaryButton from "../buttons/button";
import { Dialog } from "primereact/dialog";
import CustomUploadFile from "../UploadFile/CustomUploadFile";
import { useSelector } from "react-redux";

const UserAddCard = ({
  heading,
  save,
  children,
  SubHeading,
  identifier,
  visible,
  setVisible,
  image,
  closePopup,
  extraClass,
  data,
}) => {
  const userProfile = useSelector((state) => state.user.data);
  return (
    <Dialog
      visible={visible}
      modal
      className={
        "border-round-lg md:col-5 col-11 p-0 h-8 overflow-visible " + extraClass
      }
      onHide={() => setVisible(false)}
      content={({ hide }) => (
        <div className="flex justify-content-center border-round-lg inter">
          <div className="signup p-5">
            <div className="text-center ">
              <span className="kagitingan font-bold lg:text-5xl text-xl inline-block mb-2">
                {userProfile?.role === "ORGANIZER"
                  ? heading
                  : data.id
                  ? "Edit " + heading
                  : "Add " + heading}
              </span>
              <div
                className={`text-sm subheading mt-2 ${!data.id ? "" : "mb-4"} `}
              >
                {SubHeading}
              </div>
              {/* {!userProfile.role === "ORGANIZER" ? ( */}
              {!data.id && userProfile.role !== "ORGANIZER" ? (
                <div className="center align-items-center text-base m">
                  <h4 className="mr-4 ">RAD RUN CLUB # </h4>
                  <span className="text-sm pink">{identifier}</span>
                </div>
              ) : (
                ""
              )}
              {/* ) : (
                ""
              )} */}
              {image ? <CustomUploadFile /> : ""}
            </div>
            <div className="grid">{children}</div>
            <div className="center flex-wrap align-items-center gap-3  ">
              <PrimaryButton
                extraClass={"lg:col-3 col-12"}
                label={"Save"}
                onClick={save}
              ></PrimaryButton>
              <PrimaryButton
                secondary
                extraClass={"lg:col-3 col-12"}
                label="cancel"
                onClick={closePopup}
              ></PrimaryButton>
            </div>
          </div>
        </div>
      )}
    ></Dialog>
  );
};

export default UserAddCard;
