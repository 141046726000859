import React from "react";
import { Scanner } from "@yudiel/react-qr-scanner";

const QRScanner = ({
  visible,
  onHideScanner = () => {},
  width = "30vw",
  onScan,
}) => {
  const handleScan = (e) => {
    if (e && e.length) {
      const obj = e[0];
      onScan(JSON.parse(obj.rawValue));
    }
  };

  return (
    <div className="scanner w-10">
      <Scanner onScan={handleScan} allowMultiple={true} />
    </div>
  );
};

export default QRScanner;
