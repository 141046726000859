import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

function GoogleMapComponent({ address }) {
  const containerStyle = {
    width: "100%",
    height: "30rem",
    borderRadius: "10px",
  };

  const center = {
    lat: address?.lat,
    lng: address?.lng,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyCXEXXkQReUKL7wxSyuvfkXJKiqOdqb1z8">
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
        {/* Marker */}
        <Marker position={center} />
      </GoogleMap>
    </LoadScript>
  );
}

export default GoogleMapComponent;
