// require('dotenv').config()
import axios from "axios";
import Constants from "./constants";
import { include } from "../utils/javascript";
import { isAuthenticated, logout } from "./auth";

const successStatus = [200, 201, 204];

const api = (method, urlEndPoint, data = null, header={}, params = {}) =>
    new Promise((myResolve) => {
        let headers = {
            "Content-Type": header.formData ? "multipart/form-data" : "application/json",
        };

        if (isAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${isAuthenticated()}`,
            };
        }

        axios({
            method,
            url: Constants.BASE_URL + urlEndPoint,
            data,
            headers,
            params,
        })
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {
                if (err.response) {
                    myResolve({
                        message: err.response.data.message,
                        data: err.response.data.data,
                        success: err.response.data.success,
                    });
                }
                if (err?.response?.status === 401) {
                    logout(() => {
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 400);
                    });
                } else {
                    myResolve({
                        message: err.toString(),
                        data: { ...err },
                        success: false,
                    });
                }
            });
    });
const multipartApi = (method, urlEndPoint, data = null) =>
    new Promise((myResolve) => {
        let headers = {
            "Content-Type": "multipart/form-data",
        };

        if (isAuthenticated()) {
            headers = {
                ...headers,
                Authorization: `Bearer ${isAuthenticated()}`,
            };
        }

        axios({
            method,
            url: Constants.BASE_URL + urlEndPoint,
            data,
            headers,
        })
            .then((response) => {
                myResolve({
                    message: response.data.message,
                    data: response.data.data,
                    success: response.data.success,
                    statusCode: include(successStatus, response.status),
                });
            })
            .catch((err) => {
                if (err.response.data.message) {
                    myResolve({
                        message: err.response.data.message,
                        success: false,
                        statusCode: false,
                    });
                }
                myResolve({
                    message: err.response.data,
                    success: false,
                    statusCode: false,
                });
            });
    });

export { api, multipartApi };
