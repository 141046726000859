import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { showFormErrors } from "../../utils/commonFunctions";
import { verifyOTP } from "../../redux1/Slices/registerSlice";
import { useDispatch } from "react-redux";
import SuccessCard from "../../components/cards/SuccessCard";
import Topbar from "../../layout/Topbar";
import CustomOtpCard from "../../components/cards/CustomOtpCard";

export const OtpVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [otp, setOtp] = useState("");
  const { state } = useLocation();

  const handleVerify = () => {
    if (showFormErrors(otp, setOtp)) {
      const payload = {
        otp: otp,
        email: state.email,
        otpType: state.otpType,
      };
      dispatch(verifyOTP({ payload, setSuccess })).then((value) => {
        setUserRole(value.payload);
      });
    }
  };

  return (
    <>
      <Topbar />
      <div className="background-image">
        <div className="flex justify-content-center align-items-center w-full h-100-top">
          {success ? (
            <SuccessCard
              userRole={userRole}
              submit={() => navigate("/login")}
              buttonLabel={"BACK TO LOGIN"}
              heading={"Congratulations"}
              subHeading={"Your account has created successfully."}
            />
          ) : (
            <CustomOtpCard
              heading={"OTP VERIFICATION"}
              onClick={handleVerify}
              state={state}
              setOtp={setOtp}
              otp={otp}
            />
          )}
        </div>
      </div>
    </>
  );
};
