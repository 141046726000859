import React, { useEffect } from "react";
import CustomTable from "../../../components/table/table";
import Topbar from "../../../layout/Topbar";
import { Footer } from "../../../components/Footer/Footer";
import { ReactComponent as Empty } from "../../../assets/svg/empty.svg";
import ContentCard from "../../../components/cards/ContentCard";
import { TableEventDetails } from "./TableEventDetails";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getChildStats } from "../../../redux1/Slices/radRunSlice";
export const Profile = () => {
  const { ProfileColumns } = TableEventDetails();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { childId } = useParams();

  const childStats = useSelector((state) => state.radRun.childStats);

  useEffect(() => {
    dispatch(getChildStats({ childId }));
  }, [childId]);

  console.log(childStats);
  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <Empty />
        <h2 className="inter">Not Found</h2>
      </div>
    );
  };
  return (
    <>
      <Topbar></Topbar>
      <div className="content-box border-round-xl px-3">
        <p className="skyblue">
          <span className="cursor-pointer" onClick={() => navigate(-1)}>
            Stats
          </span>{" "}
          {">"}{" "}
          {childStats?.childDetails?.firstName +
            " " +
            childStats?.childDetails?.lastName}
        </p>
        <div
          className="flex justify-content-between border-round-xl p-3  mb-4 "
          style={{ backgroundColor: "#F4F4F4" }}
        >
          <div className="flex">
            <div>
              <h1 className="lg:text-5xl text-xl kagitingan my-3">
                {childStats?.childDetails?.firstName +
                  " " +
                  childStats?.childDetails?.lastName}
              </h1>
              <p>
                Total RAD RUNs -{" "}
                {childStats?.data?.reduce((accumulator, currentObject) => {
                  return accumulator + currentObject["radRunsCompleted"];
                }, 0)}{" "}
              </p>
            </div>
            <p className="mt-0 mb-4 ml-5 flex align-items-center skyblue">
              {childStats?.childDetails?.gender}
            </p>{" "}
          </div>{" "}
          <h2 className="pink font-normal">
            ({childStats?.childDetails?.radRunIdentifier})
          </h2>
        </div>
        <div
          className="shadow-2 border-round-xl"
          style={{ minHeight: "30rem" }}
        >
          <ContentCard
            heading={"All Results"}
            headingSize={"text-2xl text-black"}
            search={false}
          >
            <CustomTable
              columns={ProfileColumns}
              emptyMessage={emptyMessage}
              data={childStats ? childStats?.data : []}
            />
          </ContentCard>
        </div>
      </div>
      <div className="h-12rem"></div>
      <Footer></Footer>
    </>
  );
};
