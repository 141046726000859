import React, { useState } from "react";
import DummyImage from "../../assets/icon/dummyImageFile.png";
import { imagePath, uploadFile } from "../../utils/commonFunctions";

export default function CustomUploadFile({ data, setData }) {
  const customBase64Uploader = async (event) => {
    // convert file to base64 encoded
    const file = event?.target?.files[0];
    const uploadImage = await uploadFile(file);
    setData({ ...data, image: uploadImage });
    const reader = new FileReader();
    let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
    };
  };

  return (
    <>
      <div className="flex align-items-center justify-content-center gap-3 py-3">
        <small className="shadow-2 p-3 px-4 bg-white border-round-lg ">
          <img
            src={data.image ? imagePath(data.image) : DummyImage}
            alt=""
            width={data.image ? 50 : 31}
          />
        </small>

        <input
          accept="image/*"
          id="icon-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={customBase64Uploader}
        />
        <label htmlFor="icon-button-file" class>
          <span className="border-1 border-round-lg border-gray-300 p-1 bg-white text-gray-700 text-xs cursor-pointer">
            <i className="pi pi-cloud-download text-xs text-900 "></i> Upload
            Photo
          </span>
        </label>
      </div>
    </>
  );
}
