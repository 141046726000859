import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table/table";
import Topbar from "../../../layout/Topbar";
import { Footer } from "../../../components/Footer/Footer";
import { ReactComponent as Empty } from "../../../assets/svg/empty.svg";
import ContentCard from "../../../components/cards/ContentCard";
import { TableEventDetails } from "./TableEventDetails";
import { useParams } from "react-router-dom";
import { getRadRunByEventId } from "../../../redux1/Slices/radRunSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "primereact/hooks";

export const EventDetails = () => {
  const { EventColumns } = TableEventDetails();
  const { id } = useParams();
  const dispatch = useDispatch();
  const radRunData = useSelector((state) => state.radRun.radRunByEventData);

  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);

  const [filterData, setFilterData] = useState({
    searchKeys: JSON.stringify(["child.fullName"]),
    searchString: "",
  });

  useEffect(() => {
    dispatch(getRadRunByEventId({ id, filterData }));
  }, [id, filterData]);

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <Empty />
        <h2 className="inter">Not Found</h2>
      </div>
    );
  };

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  return (
    <>
      <Topbar></Topbar>
      <div className="content-box border-round-xl px-3">
        <h1 className="lg:text-5xl text-xl kagitingan my-3">Latest Results</h1>
        <div
          className="shadow-2 border-round-xl"
          style={{ minHeight: "30rem" }}
        >
          <ContentCard
            heading={radRunData && radRunData[0]?.event?.name}
            headingSize={"text-2xl"}
            inputSearch={inputSearch}
            filterData={filterData}
            setSearch={setSearchValue}
            placeholder={"by Participent"}
          >
            <CustomTable
              columns={EventColumns}
              emptyMessage={emptyMessage}
              data={radRunData}
            />
          </ContentCard>
        </div>
      </div>
      <div className="h-12rem"></div>
      {/* <Footer></Footer> */}
    </>
  );
};
