import { HashRouter, Route, Routes } from "react-router-dom";
import { PrivateRoutes } from "./routes/routes";
import { ProtectedRoute } from "./routes";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "./styles.scss";
import Register from "./pages/signup/Register";
import Login from "./pages/login";
import ForgotPassword from "./pages/login/ForgotPassword";
import { OtpVerification } from "./pages/signup/OtpVerification";
import ResetPassword from "./pages/login/ResetPasswrd";
import ResetSuccess from "./pages/login/ResetSuccess";
import ToastContainer from "./components/toast";
// import SelectRegisterType from "./pages/signup/SelectRegisterType";
import { StartRadRun } from "./view/Event/StartRadRun";
import { Events } from "./view/Event/Events";
import EventInfo from "./view/Event/EventInfo";
import { AllEvents } from "./view/Event/LatestEvents/AllEvents";
import { EventDetails } from "./view/Event/LatestEvents/EventDetails";
import { Stats } from "./view/Event/LatestEvents/Stats";
import { Profile } from "./view/Event/LatestEvents/Profile";
import { hideAddSchoolModal } from "./redux1/Slices/registerSlice";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import AddSchoolCard from "./components/cards/AddSchoolClubCard";
import Clubs from "./view/common/Clubs";
import ClubDetails from "./view/common/ClubDetails";
import { MyEvents } from "./view/Event/MyEvents";

function App() {
  const isAddSChoolModalVisible = useSelector((state) => state.register.isAddSchoolModalVisible);

  return (
    <div className="App">
      <ToastContainer />
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          {/* <Route
            path="/select-register_type"
            element={<SelectRegisterType />}
          /> */}
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/otp" element={<OtpVerification />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/success" element={<ResetSuccess />} />
          <Route path="/start-radrun" element={<StartRadRun />} />
          <Route path="/events" element={<Events />} />
          <Route path="/event-info/:id" element={<EventInfo />} />
          <Route path="/my-events" element={<MyEvents />} />
          <Route path="/latest-events" element={<AllEvents />} />
          <Route path="/event-details/:id" element={<EventDetails />} />
          <Route path="/stats" element={<Stats />} />
          <Route path="/profile/:childId" element={<Profile />} />
          <Route path="/clubs" element={<Clubs />} />
          <Route path="/club/:clubId" element={<ClubDetails />} />
          <Route element={<ProtectedRoute />}>
            {PrivateRoutes.map((route, key) => (
              <Route
                key={key}
                path={route.path}
                element={route.element}
                exact={route.exact}
              >
                {route.items?.map((item, index) => (
                  <Route
                    key={index}
                    path={item.path}
                    element={item.element}
                    exact={item.exact}
                  />
                ))}
              </Route>
            ))}
          </Route>
          {/* <Route path="*" element={<Notfound />} /> */}
        </Routes>
      </HashRouter>
      <Dialog
        visible={isAddSChoolModalVisible}
        modal
        breakpoints={{ '1280px': '90vw', '430px': '98vw' }}
        style={{ width: "80vw" }}
        className="border-round-lg"
        onHide={hideAddSchoolModal}
        content={<AddSchoolCard />}
      ></Dialog>
    </div>
  );
}

export default App;
