import { EditProfile } from "../view/EditProfile";
import Dashboard from "../view/common/Dashboard";

export const PrivateRoutes = [
  {
    path: "/dashboard",
    name: "OnBoarding",
    element: <Dashboard />,
  },
  {
    path: "/edit_profile",
    name: "EditProfile",
    element: <EditProfile />,
  },
];
