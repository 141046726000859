import React from "react";
import deleteIcon from "../../assets/icon/Delete.png";
import PrimaryButton, { ButtonPosition } from "../buttons/button";

// Main Dynamic Popup for all projects

export default function ConfirmDailog({ dialog, setDialog, ...props }) {
  const initalState = {
    visible: false,
    title: "",
    description: null,
    type: "",
    cancel: false,
    saveFunction: null,
    buttonCol: "",
  };
  return (
    <>
      {dialog?.visible ? (
        <div
          className="fixed w-full h-full left-0 bg-black-alpha-60 top-0 flex justify-content-center align-items-center"
          style={{ zIndex: 999999999 }}
          {...props}
        >
          <div
            className="bg-white p-5 border border-round-lg md:col-5 col-10  relative"
            style={{ maxWidth: "630px" }}
          >
            {dialog?.cancelIcon ? (
              <div className="text-right">
                <i
                  className="pi pi-times cursor-pointer"
                  onClick={() => setDialog(initalState)}
                />
                <h2 className={`text-base subheading font-normal`}>
                  {dialog?.title}
                </h2>
              </div>
            ) : null}
            {dialog?.type === "DELETE" ? (
              <img src={deleteIcon} alt="Delete Icon" width={40} height={40} />
            ) : null}
            {dialog?.title && !dialog.cancelIcon ? (
              <h2 className={`text-base subheading font-normal`}>
                {dialog?.title}
              </h2>
            ) : null}
            <p
              className={
                dialog.type === "SHARE"
                  ? "border  overflow-hidden p-3 text-overflow-ellipsis"
                  : ""
              }
            >
              {dialog?.description ? dialog?.description : null}
            </p>
            <div className="px-2">
              <ButtonPosition
                position={
                  dialog?.buttonPosition ? dialog?.buttonPosition : "end"
                }
              >
                {dialog?.saveFunction ? (
                  <PrimaryButton
                    label={
                      dialog?.type === "DELETE"
                        ? "Delete"
                        : dialog?.type === "CONFIRM"
                        ? "Yes"
                        : dialog?.type === "SAVE"
                        ? "Save"
                        : dialog?.type === "LOGOUT"
                        ? "Log Out"
                        : dialog?.type === "COMPLETE"
                        ? "Complete"
                        : dialog.saveLabel
                    }
                    col={dialog?.buttonCol || 4}
                    onClick={() => dialog?.saveFunction()}
                    className={
                      dialog.type === "DELETE"
                        ? "bg-red-500 border-red-500"
                        : ""
                    }
                    severity={dialog.type === "DELETE" ? "danger" : ""}
                    icon={dialog.type === "SHARE" ? "pi pi-link" : ""}
                  />
                ) : null}
                {dialog?.cancel ? (
                  <PrimaryButton
                    label="Cancel"
                    col={dialog?.buttonCol || 4}
                    onClick={() => setDialog(initalState)}
                    secondary
                  />
                ) : null}
              </ButtonPosition>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export function CategoryDialog({
  children,
  title,
  visible,
  setVisible,
  saveFunction,
  cancelFunction,
  ...props
}) {
  return (
    <>
      {visible ? (
        <div
          className="fixed w-full h-full left-0 bg-black-alpha-60 top-0 flex justify-content-center align-items-center"
          style={{ zIndex: 999999999 }}
          {...props}
        >
          <div className="bg-white p-5 border w-full md:w-7 m-5 ">
            <h2 className="text-center mb-0">
              {title ? title : "Add More Categories"}
            </h2>
            <div className="text-center mb-3">
              <small className="text-500">
                (Min. one Category is required)
              </small>
            </div>
            <div
              className="flex flex-wrap gap-3 overflow-auto mb-5"
              style={{ maxHeight: "50vh" }}
            >
              {children}
            </div>
            <ButtonPosition>
              {cancelFunction ? (
                <PrimaryButton
                  label="Cancel"
                  onClick={() => cancelFunction()}
                  secondary
                />
              ) : null}
              {saveFunction ? (
                <PrimaryButton label="Save" onClick={() => saveFunction()} />
              ) : null}
            </ButtonPosition>
          </div>
        </div>
      ) : null}
    </>
  );
}
