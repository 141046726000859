import React from "react";

export default function InputLayout({
  labelClassName,
  label,
  name,
  required,
  col,
  maxLength,
  currentLength,
  extraClassName,
  errorMessage,
  data,
  children,
}) {
  col = parseInt(col);
  if (col > 12) {
    col = 12;
  }

  return (
    // <div className={`p-0 mb-4 col-12  ${col > 6 ? "md:col-12 lg:col-12" : col === 4 ? "md:col-4 lg:col-4" : col === 2 ? "md:col-4 lg:col-2" : "md:col-6 lg:col-6"} ${extraClassName}`}>
    <div
      className={`col-12  ${
        col > 6
          ? "md:col-12 lg:col-12"
          : col < 6
          ? `md:col-6 lg:col-${col}`
          : "md:col-6 lg:col-6"
      } ${extraClassName ? extraClassName : "mb-1"}`}
    >
      {label ? (
        <div className="flex justify-content-between">
          <label
            className={`block mb-2 text-sm heading-color font-semibold ${labelClassName}`}
            htmlFor={name}
          >
            {label}&nbsp;
            {required ? <span className="p-error">*</span> : ""}
          </label>
          <span className="text-500">
            {maxLength ? `(${currentLength}/${maxLength})` : null}
          </span>
        </div>
      ) : null}

      <div className="w-full">{children}</div>
      <div>
        {errorMessage || data?.formErrors?.[name] ? (
          <small className="p-error">
            {errorMessage || data?.formErrors?.[name]}
          </small>
        ) : null}
      </div>
    </div>
  );
}
