import React, { useEffect, useState } from "react";
import { allValidations } from "../../../utils/formValidations";
import { showFormErrors } from "../../../utils/commonFunctions";
import {
  CustomDropDown,
  CustomFloatInput,
  CustomInputphone,
  CustomTextArea,
} from "../../input/input";
import PrimaryButton from "../../buttons/button";
import { City, Country, State } from "country-state-city";
import { addPublicEvent } from "../../../redux1/Slices/eventSlice";
import { useDispatch } from "react-redux";

export const RequestEvent = () => {
  const [isoCode, setIsoCode] = useState("au");

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    postCode: "",
    country: "",
    state: "",
    city: "",
  });
  const dispatch = useDispatch();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [city, setCity] = useState();

  useEffect(() => {
    setCountry(Country.getAllCountries());
  }, []);

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    if (name === "country") {
      setData({ ...data, [name]: value.name });
      setState(State.getStatesOfCountry(value.isoCode));
    }
    if (name === "state") {
      setData({ ...data, [name]: value.name });
      setCity(City.getCitiesOfState(value.countryCode, value.isoCode));
    }
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const submitForm = () => {
    let ignore = ["comments"];
    if (showFormErrors(data, setData, ignore)) {
      dispatch(addPublicEvent({ data, setData }));
    }
  };

  return (
    <>
      <div className="signup p-3 ">
        <div className="content-box lg:px-3">
          <div className=" lg:text-3xl text-xl py-5   font-bold kagitingan">
            About You
          </div>
          <div className="grid">
            <CustomFloatInput
              name="firstName"
              value={data?.firstName}
              data={data}
              onChange={handleChange}
              col={6}
              label={"First Name"}
            ></CustomFloatInput>
            <CustomFloatInput
              name="lastName"
              value={data?.lastName}
              data={data}
              onChange={handleChange}
              col={6}
              label={"Last Name"}
            ></CustomFloatInput>
            <CustomFloatInput
              name="email"
              data={data}
              value={data?.email}
              onChange={handleChange}
              col={6}
              label={"Email"}
            ></CustomFloatInput>

            <CustomInputphone
              col={6}
              label={"Mobile Number"}
              value={data?.phone}
              name="phone"
              data={data}
              isoCode={isoCode}
              onChange={handleChange}
            ></CustomInputphone>
          </div>
          <div className=" lg:text-3xl text-xl py-5   font-bold kagitingan">
            About Your Proposed rad run
          </div>
          <div className="grid">
            <CustomDropDown
              options={country?.map((item, index) => {
                return { name: item.name, isoCode: item.isoCode };
              })}
              optionLabel="name"
              data={data}
              col={6}
              value={data?.country}
              label={"Country"}
              name="country"
              onChange={handleChange}
            ></CustomDropDown>
            <CustomDropDown
              options={state?.map((item, index) => {
                return {
                  name: item.name,
                  isoCode: item.isoCode,
                  countryCode: item.countryCode,
                };
              })}
              optionLabel="name"
              data={data}
              disabled={!data?.country}
              col={6}
              label={"State"}
              name="state"
              value={data?.state}
              onChange={handleChange}
            ></CustomDropDown>
            <CustomDropDown
              options={city?.map((item, index) => {
                return { name: item.name, value: item.name };
              })}
              optionLabel="name"
              data={data}
              col={6}
              label={"City"}
              disabled={!data?.state}
              name="city"
              value={data?.city}
              onChange={handleChange}
            ></CustomDropDown>

            <CustomFloatInput
              col={6}
              label={"Postcode"}
              name="postCode"
              data={data}
              onChange={handleChange}
            ></CustomFloatInput>
            <CustomTextArea
              name={"comments"}
              col={12}
              inputClass={"h-10rem "}
              label={
                "Is there anything else you would like to tell us?(Optional)"
              }
              data={data}
              onChange={handleChange}
            ></CustomTextArea>
            <div className="center w-full my-3 lg:pb-7 lg:px-0 px-3">
              <PrimaryButton
                col={2}
                label={"Submit"}
                onClick={submitForm}
              ></PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
