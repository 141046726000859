import React, { useEffect, useState } from "react";
import Topbar from "../../layout/Topbar";
import { Footer } from "../../components/Footer/Footer";
import ContentCard from "../../components/cards/ContentCard";
import EventCard from "../../components/cards/EventCard";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getNearestEvents } from "../../redux1/Slices/eventSlice";
import { useDispatch } from "react-redux";
import Constants from "../../services/constants";
import moment from "moment-timezone";
import { useDebounce } from "primereact/hooks";

export const Events = () => {
  const navigate = useNavigate();
  const [location, setLocation] = useState();
  const [events, setEvents] = useState();
  const dispatch = useDispatch();
  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);
  const searchKeys = JSON.stringify(["name"]);
  const [filterData, setFilterData] = useState({
    country: "",
    state: "",
    city: "",
  });

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const response = await axios.get("https://ipapi.co/json/");
        const { latitude, longitude } = response.data;
        setLocation({ latitude, longitude });
        const payload = {
          searchKeys,
          searchString: debouncedValue,
          lng: longitude,
          lat: latitude,
          ...filterData,
        };
        dispatch(
          getNearestEvents({
            payload,
            searchKeys,
            inputSearch,
            setEvents,
          })
        );
      } catch (error) {
        console.error("Error fetching location data:", error);
      }
    };

    fetchLocation();
  }, [filterData]);

  useEffect(() => {
    if (location) {
      const payload = {
        searchKeys,
        searchString: debouncedValue,
        lng: location.longitude,
        lat: location.latitude,
        ...filterData,
      };
      dispatch(
        getNearestEvents({
          payload,
          setEvents,
        })
      );
    }
  }, [debouncedValue]);

  return (
    <>
      <Topbar></Topbar>
      <div className="content-box px-4">
        <ContentCard
          extraClass="py-3"
          heading={"Events"}
          inputSearch={inputSearch}
          setSearch={setSearchValue}
          filterData={filterData}
          setFilterData={setFilterData}
          placeholder={"by Event"}
          filter
        >
          <div className="grid p-2 mt-3">
            {events && events.length
              ? events?.map((event) => {
                  return (
                    <EventCard
                      src={Constants.HOST + event?.image}
                      title={event?.name}
                      date={moment(event?.date)
                        .tz(event?.timeZone)
                        .format("dddd DD MMM YYYY")}
                      time={moment(event?.time)
                        .tz(event?.timeZone)
                        .format("hh:mm A")}
                      timeZone={event?.timeZone}
                      address={event?.address?.name}
                      distance={event?.distance}
                      onClick={() => navigate("/event-info/" + event._id)}
                    />
                  );
                })
              : "No near by events found."}
          </div>
        </ContentCard>
      </div>
      <div className="h-26rem"></div>
      <Footer></Footer>
    </>
  );
};
