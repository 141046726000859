import React from "react";
import { CustomFloatInput, CustomFloatPassword } from "../input/input";
import PrimaryButton from "../buttons/button";
import { useNavigate } from "react-router-dom";

const CustomSignInCard = ({
  heading,
  submit,
  SubHeading,
  forgotPassword,
  buttonLabel,
  signIn,
  resetPassword,
  data,
  handleChange,
}) => {
  const navigate = useNavigate();
  return (
    <div className="signup md:col-6 lg:col-4 col-10 p-5 my-3 ">
      <div className="text-center lg:text-5xl text-xl pb-4 pb-4">
        <span className="kagitingan font-bold ">{heading}</span>
        <div className="text-sm subheading mt-2 ">{SubHeading}</div>
      </div>
      {!resetPassword ? (
        <div className="grid">
          <CustomFloatInput
            name="email"
            data={data}
            onChange={handleChange}
            col={12}
            label={"Email"}
          ></CustomFloatInput>

          {!forgotPassword ? (
            signIn ? (
              <CustomFloatPassword
                col={12}
                label={"Password"}
                name="password"
                data={data}
                onChange={handleChange}
                extraClassName={"mb-2"}
              ></CustomFloatPassword>
            ) : (
              <CustomFloatPassword
                name="confirmPassword"
                data={data}
                onChange={handleChange}
                extraClassName={"mb-2"}
                col={12}
                label={"Confirm Password"}
              ></CustomFloatPassword>
            )
          ) : (
            ""
          )}
        </div>
      ) : (
        <>
          <CustomFloatPassword
            name="password"
            data={data}
            onChange={handleChange}
            col={12}
            label={"Password"}
            extraClassName={"mb-2"}
          ></CustomFloatPassword>
          <CustomFloatPassword
            name="confirmPassword"
            data={data}
            onChange={handleChange}
            extraClassName={"mb-2"}
            col={12}
            label={"Confirm Password"}
          ></CustomFloatPassword>{" "}
        </>
      )}
      {resetPassword ? (
        ""
      ) : !forgotPassword ? (
        <div className="center justify-content-end mb-4">
          <span
            className="pink cursor-pointer font-semibold underline"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="center align-items-center flex-column mt-3">
        <PrimaryButton
          col={4}
          extraClass={""}
          label={buttonLabel}
          onClick={submit}
        ></PrimaryButton>
        <h4 className="font-normal text-900 mb-0 ">
          {forgotPassword ? (
            <>
              Remember Password? &nbsp;
              <span
                className="pink font-bold cursor-pointer underline"
                onClick={() => navigate("/login")}
              >
                Login
              </span>{" "}
            </>
          ) : resetPassword ? (
            ""
          ) : (
            <>
              <span className="text-center text-sm ">
                Haven't joined the club yet? Let's fix that! <br />
                <span
                  className="pink font-bold cursor-pointer underline"
                  onClick={() => navigate("/register")}
                >
                  Register
                </span>{" "}
                for FREE and let the fun begin! &nbsp;
              </span>
            </>
          )}
        </h4>
      </div>
    </div>
    // </div>
  );
};

export default CustomSignInCard;
