import React from "react";
import { Button } from "primereact/button";
import { Ripple } from "primereact/ripple";

export const ButtonPosition = ({ children, position, flexWrap, className }) => {
  return (
    <div
      className={`flex gap-3 align-items-center justify-content-${
        position ? position : "end"
      } ${flexWrap ? "flex-wrap" : ""} ${className ? className : ""}`}
    >
      {children}
    </div>
  );
};

export default function PrimaryButton({
  type,
  onClick,
  label,
  col,
  extraClass,
  loading,
  className,
  disable,
  secondary,
  ...props
}) {
  return (
    <div className={`p-0 col ${col ? "md:col-" + col : ""} ${extraClass}`}>
      <Button
        type={type}
        label={label}
        onClick={onClick}
        loading={loading}
        disabled={disable}
        className={`font-semibold p-button-info w-full border-round-lg ${className}`}
        style={{
          background: secondary ? "rgba(208, 208, 208, 1)" : "",
          border: secondary ? "none" : "",
        }}
        {...props}
      >
        {" "}
        <Ripple />
      </Button>
    </div>
  );
}
export function PrimaryButtonOutlined({
  type,
  onClick,
  label,
  col,
  extraClass,
  className,
  loading,
  ...props
}) {
  return (
    <div className={`${col ? col : ""} ${extraClass}`}>
      <Button
        type={type}
        label={label}
        onClick={onClick}
        loading={loading}
        className={`p-button-outlined w-full border-round-lg p-button-primary ${className}`}
        {...props}
      >
        <Ripple />
      </Button>
    </div>
  );
}
