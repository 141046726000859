import { equal, greaterThan, length, notEqual } from "./javascript";
import {
  firstLetterToUppercase,
  stringValidation,
  emailValidation,
  number,
  letterSpacing,
  atLeastOneNumber,
  atLeastOneCapitalCase,
  atLeastOneLowerCase,
} from "./regex";

export const allValidations = (name, value, state, ignore = []) => {
  const formErrors = { ...state.formErrors };
  if (ignore.includes(name)) {
    if (formErrors[name]) {
      formErrors[name] = "";
    }
    return formErrors;
  }
  let Password = "";
  if (name === "password") {
    Password = value;
  }

  if (typeof value === 'boolean') value = JSON.stringify(value);
  switch (name) {
    case "email":
      if (equal(length(value))) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else if (!emailValidation(value)) {
        formErrors[name] = `Please enter valid email!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "phone":
      if (value) {
        let phone = value.replace(/\s/g, "");
        if (equal(length(value))) {
          formErrors[name] = `${letterSpacing(name)} is required!`;
        } else if (phone.length <= 10) {
          formErrors[name] = `${firstLetterToUppercase(name)} is not valid.`;
        } else {
          formErrors[name] = "";
        }
        break;
      } else {
        formErrors[name] = `${letterSpacing(name)} is required!`;
      }
      break;
    case "fullName":
    case "store_name":
    case "name":
    case "address":
    case "companyName":
    case "school":
    case "city":
    case "otp":
    case "gender":
    case "department":
    case "oldPassword":
      if (!value || equal(length(value))) {
        formErrors[name] = `${letterSpacing(name)} is required!`;
      } else if (greaterThan(length(value), 70)) {
        formErrors[name] = `${firstLetterToUppercase(
          name
        )} exceeds character limit. Maximum allowed: 70 characters.`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "organizationName":
      if (equal(length(value))) {
        formErrors[name] = `${state.organizerType === "Clubs/Groups"
          ? "Club/Group Name"
          : "School Name"
          } is required!`;
      } else if (greaterThan(length(value), 70)) {
        formErrors[name] = `${firstLetterToUppercase(
          name
        )} exceeds character limit. Maximum allowed: 70 characters.`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "loginPassword":
      if (value.length === 0) {
        formErrors[name] = `Password is required!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "password":
      if (value.length === 0) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else if (value.length < 8) {
        formErrors[name] = `${firstLetterToUppercase(
          name
        )} must be greater than 8 characters!`;
      } else if (!atLeastOneLowerCase.test(value)) {
        formErrors[name] = `${firstLetterToUppercase(
          name
        )} must have one lower case letter!`;
      } else if (!atLeastOneCapitalCase.test(value)) {
        formErrors[name] = `${firstLetterToUppercase(
          name
        )} must have one upper case letter!`;
      } else if (!atLeastOneNumber.test(value)) {
        formErrors[name] = `${firstLetterToUppercase(
          name
        )} must have one upper case letter!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "confirmPassword":
    case "newConfirmPassword":
      if (equal(length(value))) {
        formErrors[name] = `Confirm Password is required!`;
      } else if (notEqual(state?.password, value)) {
        formErrors[name] = `${name === "newConfirmPassword" ? "New " : ""
          } Password and Confirm Password is not match!`;
      } else if (equal(state?.password, value)) {
        formErrors[name] = "";
      }
      break;

    case "postalCode":
      if (value.length === 0) {
        formErrors[name] = `${letterSpacing(name)} is required.`;
      } else if (value.length > 70) {
        formErrors[name] = `${firstLetterToUppercase(
          name
        )} exceeds character limit. Maximum allowed: 70 characters.`;
      } else {
        // ZIP code is valid
        formErrors[name] = "";
      }
      break;

    case "otherLanguages":
      if (value.length === 0) {
        formErrors[name] = `${letterSpacing(name)} is required.`;
      } else {
        // ZIP code is valid
        formErrors[name] = "";
      }
      break;

    case "categoryName":
      if (equal(length(value))) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else if (!stringValidation(value)) {
        formErrors[name] = `Unnecessary space in word!`;
      } else {
        formErrors[name] = "";
      }
      break;

    case "title":
    case "firstName":
    case "lastName":
    case "state":
    case "expiringOn":
    case "startDate":
    case "category":
    case "service":
    case "lengthOfContract":
    case "timeframe":
    case "class":
    case "club":
    case "team":
    case "house":
    case "dob":
    case "comments":
    case "location":
    case "notes":
    case "subCategory":
      if (value) {
        if (typeof value === 'string' && !value.trim()) {
          formErrors[name] = `${firstLetterToUppercase(name)} is required!`;

        } else if (equal(length(value))) {
          formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
        } else {
          formErrors[name] = "";
        }
      } else {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      }
      break;
    case "numberOfEmployees":
    case "minBudget":
    case "repetition":
    case "maxBudget":
    case "isLicensed":
    case "age":
    case "dob":
      if (value === null || value === 0 || value === "") {
        formErrors[name] = `${letterSpacing(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "expertise":
    case "names":
    case "sizes":
    case "materialTags":
    case "processName":
    case "labels":
    case "machines":
    case "tobaco_license":
    case "seller_permit":
    case "label":
      if (!value.length) {
        formErrors[name] = `${letterSpacing(name)} are required!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "qty":
    case "noOflabels":
    case "capacityPerHour":
      if (!value) {
        if (name === "noOflabels") {
          formErrors[name] = `No of labels is required!`;
        } else {
          formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
        }
      } else {
        formErrors[name] = "";
      }
      break;
    case "operation":
    case "season":
    case "classType":
    case "group":
    case "jobPosition":
    // case "type":
    case "injectionType":
    case "supplier":
    case "unit":
    case "material":
      if (!value.name) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "childIdentifiedAsIslander":
    case "hasDisabilityOrLongTermHealthCondition":
    case "disabilityOrDimensionOfRestrictedAccess":
    case "otherSpecificationValue":
    case "howManyDaysChildWasPhysicallyActive":
      if (!value) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "country":
      if (!value || !Object.keys(value).length) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;
    case "postCode":
      if (!value) {
        formErrors[name] = `Post code is required!`;
      } else if (value.toString().length < 4 || value.toString().length > 4) {
        formErrors[name] = 'Post code should be exactly of 4 digits.'
      } else {
        formErrors[name] = "";
      }
      break;
    case "membersCount":
      if (!value || value == 0) {
        formErrors[name] = `Members count should be gretaer than 0.`;
      } else {
        formErrors[name] = "";
      }
      break;
    default:
      if (!value) {
        formErrors[name] = `${firstLetterToUppercase(name)} is required!`;
      } else {
        formErrors[name] = "";
      }
      break;
  }
  return formErrors;
};
