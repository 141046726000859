import React from "react";
import PrimaryButton from "../buttons/button";
import Success from "../../assets/images/Done.png";
import { useNavigate } from "react-router-dom";

const SuccessCard = ({ userRole, subHeading }) => {
  const navigate = useNavigate();
  return (
    <div
      className="signup lg-col-4 md:col-4 col-10 p-5 my-3"
      style={{ maxWidth: "500px" }}
    >
      <div className="text-center  pb-4 pb-4">
        <div>
          <img src={Success} alt="" width={106} />
        </div>
        <small className="kagitingan font-bold lg:text-4xl text-xl  my-3 inline-block ">
          Congratulations
        </small>
        <div className="text-sm flex justify-content-center subheading">
          <small className="lg:w-9 ">{subHeading}</small>
        </div>
      </div>
      <div className="center align-items-center flex-column">
        <PrimaryButton
          col={7}
          label={`Sign In to continue`}
          onClick={() => navigate("/login")}
        ></PrimaryButton>
      </div>
    </div>
  );
};

export default SuccessCard;
