import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import Constants from "../../services/constants";
import { hideDataLoader, showDataLoader } from "./loaderSlice";
import { showToast } from "./toastSlice";

const initialState = {
    data: null,
    // loading: false,
    // error: null,
};

export const createRun = createAsyncThunk(
    "api/rad-run",
    async ({ data, setIsScannerOpen }, { dispatch, getState }) => {
        try {
            dispatch(showDataLoader());
            const res = await api(
                "post",
                Constants.END_POINT.RAD_RUN,
                data
            );
            if (res.success && res.data) {
                dispatch(showToast({ severity: "success", summary: res.message }));
                dispatch(getRadRunByEventId({ id: data.eventId }));
                setIsScannerOpen(false);
                // dispatch(radRunSlice.actions.setPrivteEvent(res.data));s
                return res.data;
            } else {
                dispatch(showToast({ severity: "error", summary: res.message }));
                return Promise.reject(res.message);
            }
        } catch (error) {
            dispatch(showToast({ severity: "error", summary: error.message }));
            return Promise.reject("An error occurred.");
        } finally {
            dispatch(hideDataLoader());
        }
    }
);

export const getRadRunByEventId = createAsyncThunk(
    "api/rad-run",
    async ({ id, filterData }, { dispatch, getState }) => {
        try {
            dispatch(showDataLoader());
            const searchParams = new URLSearchParams();

            if (filterData) {
                Object.keys(filterData).forEach(key => {
                    if (filterData[key] !== undefined && filterData[key] !== null) {
                        searchParams.append(key, filterData[key]);
                    }
                });
            }

            const res = await api("get", Constants.END_POINT.RAD_RUN + "/" + id + "/?" + searchParams);
            if (res.success && res.data) {
                dispatch(radRunSlice.actions.setRadRunByEventId(res.data));
                return res.data;
            } else {
                dispatch(showToast({ severity: "error", summary: res.message }));
                return Promise.reject(res.message);
            }
        } catch (error) {
            dispatch(showToast({ severity: "error", summary: error.message }));
            return Promise.reject("An error occurred.");
        } finally {
            dispatch(hideDataLoader());
        }
    }
);

export const getAllStats = createAsyncThunk(
    "api/rad-run/all-stats",
    async ({ filterData }, { dispatch, getState }) => {
        try {
            dispatch(showDataLoader());
            const searchParams = new URLSearchParams();

            if (filterData) {
                Object.keys(filterData).forEach(key => {
                    if (filterData[key] !== undefined && filterData[key] !== null) {
                        searchParams.append(key, filterData[key]);
                    }
                });
            }

            const res = await api("get", Constants.END_POINT.ALL_STATS + "?" + searchParams);
            if (res.success && res.data) {
                dispatch(radRunSlice.actions.setAllStats(res.data));
                return res.data;
            } else {
                dispatch(showToast({ severity: "error", summary: res.message }));
                return Promise.reject(res.message);
            }
        } catch (error) {
            dispatch(showToast({ severity: "error", summary: error.message }));
            return Promise.reject("An error occurred.");
        } finally {
            dispatch(hideDataLoader());
        }
    }
);

export const getChildStats = createAsyncThunk(
    "api/rad-run/child-stats",
    async ({ childId }, { dispatch, getState }) => {
        try {
            dispatch(showDataLoader());

            const res = await api("get", Constants.END_POINT.ALL_STATS + "/" + childId);
            if (res.success && res.data) {
                dispatch(radRunSlice.actions.setChildStats(res.data));
                return res.data;
            } else {
                dispatch(showToast({ severity: "error", summary: res.message }));
                return Promise.reject(res.message);
            }
        } catch (error) {
            dispatch(showToast({ severity: "error", summary: error.message }));
            return Promise.reject("An error occurred.");
        } finally {
            dispatch(hideDataLoader());
        }
    }
);

const radRunSlice = createSlice({
    name: "radRun",
    initialState,
    reducers: {
        setRadRunByEventId: (state, action) => {
            state.radRunByEventData = action.payload;
        },
        setAllStats: (state, action) => {
            state.allStats = action.payload;
        },
        setChildStats: (state, action) => {
            state.childStats = action.payload;
        }
    },
});

export const { setRadRunByEventId, setAllStats, setChildStats } = radRunSlice.actions;

export default radRunSlice.reducer;
