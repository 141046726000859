import React, { useEffect, useState } from "react";
import CustomTable from "../../components/table/table";
import { ReactComponent as EmptyEvents } from "../../assets/svg/EmptyEvent.svg";
import ContentCard from "../../components/cards/ContentCard";
import Topbar from "../../layout/Topbar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClub,
  getEntitiesByClub,
  updateEntityAction,
} from "../../redux1/Slices/parentSlice";
import { useDebounce } from "primereact/hooks";
import { useParams } from "react-router-dom";
import { showFormErrors } from "../../utils/commonFunctions";
import { Dialog } from "primereact/dialog";
import AddEntityCard from "../../components/cards/AddEntityCard";
import { TableColumns } from "./TableColumns";

const ClubDetails = () => {
  const dispatch = useDispatch();
  const { clubId } = useParams();

  const [visible, setVisible] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const [dialog, setDialog] = useState({ visible: false });
  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);

  const clubDetails = useSelector((state) => state.children.club);
  const clubEntities = useSelector((state) => state.children.clubEntities);

  const searchKeys = JSON.stringify(["name"]);
  const { clubEntntiyColumns } = TableColumns();

  const [filterData, setFilterData] = useState({
    searchKeys,
    searchString: "",
  });

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <>
          <EmptyEvents />
          <h2 className="inter text-sm">No entities found.</h2>{" "}
        </>
      </div>
    );
  };

  // FETCH ENTITIES
  useEffect(() => {
    dispatch(getEntitiesByClub({ clubId, filterData }));
  }, [filterData]);

  useEffect(() => {
    dispatch(fetchClub({ clubId }));
  }, []);

  const deleteEntity = (id) => {
    setDialog({
      visible: true,
      title:
        "Are you sure you want to delete this entry? This action cannot be undone.",
      type: "DELETE",
      cancel: true,
      saveFunction: () => {
        const payload = {
          _id: id,
          isDeleted: true,
        };
        dispatch(
          updateEntityAction({ data: payload, clubId, setVisible: setDialog })
        );
      },
    });
  };

  return (
    <>
      <Topbar />
      <div className="content-box lg:py-4 p-3  ">
        <div className="shadow-2 border-round-xl">
          <div className="content-box border-round-xl px-3">
            <h1 className={"kagitingan my-0 lg:text-5xl text-xl pt-3"}>
              School/Club
            </h1>
            <div
              className="flex justify-content-between border-round-xl p-3 mb-4"
              style={{ backgroundColor: "#F4F4F4" }}
            >
              <div className="flex flex-wrap w-full justify-content-between">
                <div>
                  <h1 className="text-xl kagitingan my-3">Name</h1>
                  <p> {clubDetails?.name} </p>
                </div>
                <div>
                  <h1 className="text-xl kagitingan my-3">Contact Email</h1>
                  <p> {clubDetails?.contactEmail} </p>
                </div>
                <div>
                  <h1 className="text-xl kagitingan my-3">Number of Members</h1>
                  <p> {clubDetails?.membersCount} </p>
                </div>
                <div>
                  <h1 className="text-xl kagitingan my-3">Website</h1>
                  <p> {clubDetails?.website} </p>
                </div>
                <div>
                  <h1 className="text-xl kagitingan my-3">Phone</h1>
                  <p> {clubDetails?.phone} </p>
                </div>
              </div>{" "}
              <h2 className="pink font-normal"></h2>
            </div>
            <ContentCard
              inputSearch={inputSearch}
              setSearch={setSearchValue}
              setDialog={setDialog}
              setVisible={setVisible}
              filterData={filterData}
              setFilterData={setFilterData}
              dialog={dialog}
              placeholder={"by Entity Name"}
              button
              onClick={() => {
                setVisible(true);
              }}
              label={"Add Entity"}
              heading={""}
            >
              <div className="px-3" style={{ minHeight: "30rem" }}>
                <CustomTable
                  emptyMessage={emptyMessage}
                  columns={clubEntntiyColumns}
                  data={clubEntities}
                  edit={(row) => {
                    setEntityId(row._id);
                    setVisible(true);
                  }}
                  trash={(id) => deleteEntity(id)}
                />
              </div>
            </ContentCard>
          </div>
        </div>
      </div>
      <Dialog
        visible={visible}
        modal
        style={{ width: "40vh" }}
        className="border-round-lg"
        content={
          <AddEntityCard
            setVisible={setVisible}
            clubId={clubId}
            type={clubDetails?.type}
            entityId={entityId}
            setEntityId={setEntityId}
          />
        }
      ></Dialog>
    </>
  );
};

export default ClubDetails;
