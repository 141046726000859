import React from "react";
import Logo from "../../assets/images/Logo.jpeg";
import Facebook from "../../assets/icon/facebook.png";
import Instagram from "../../assets/icon/instagram.png";
import PrimaryButton from "../buttons/button";
import { isAuthenticated } from "../../services/auth";

export const Footer = () => {
  return (
    <div className="bg-black pt-7 lg:p-3 p-1 relative flex center">
      <div className="content-box lg:mt-6 mt-2 ">
        <div className="flex flex-wrap">
          <div className="lg:col-6 col-12">
            <img src={Logo} alt="" width={100} />
            <p className="text-white text-base lg:w-6">
              We believe that all children have the right to grow up in a
              HEALTHY environment that allows them to PLAY, have FUN and ACHIEVE
              their full potential.
            </p>
          </div>
          <div className="col  text-white flex flex-column gap-3">
            <h4 className="text-2xl text-white kagitingan my-2">
              Helpfull Link
            </h4>
            <h4 className="m-0 font-normal">Events</h4>
            <h4 className="m-0 font-normal">About</h4>
            <h4 className="m-0 font-normal">Blog</h4>
          </div>
          <div className="col  text-white flex flex-column gap-3">
            <h4 className="text-2xl text-white kagitingan my-2">Contact US</h4>

            <h4 className="m-0 font-normal ">
              {" "}
              <i className="pi pi-envelope mr-2"></i>
              <a
                className="text-white no-underline"
                href="mailto:tim@healthykidsproject.com"
              >
                info@radrun.club
              </a>
            </h4>
            <h4 className="m-0 font-normal">
              {" "}
              <i className="pi pi-phone mr-2"></i>
              <a className="text-white no-underline" href="tel:+61 414 388 747">
                +61 414 388 747
              </a>
            </h4>
          </div>
        </div>{" "}
        <hr className="content-box my-3" />
        <div className="content-box center gap-2 flex-wrap text-white lg:justify-content-between align-items-center">
          <small className="text-sm">
            Copyright © 2024 RAD RUN CLUB | All Rights Reserved
          </small>
          <small className="flex gap-3">
            <img
              src={Facebook}
              alt=""
              width={35}
              height={35}
              onClick={() =>
                window.open("https://www.facebook.com/radrunclub", "_blank")
              }
            />
            <img
              src={Instagram}
              alt=""
              width={35}
              height={35}
              onClick={() =>
                window.open("https://www.instagram.com/radrun.club/", "_blank")
              }
            />
          </small>
        </div>
      </div>
      {!isAuthenticated() ? (
        <div className="content-box lg:col-8 col-11  absolute top-0 bg-pink-700 border-round-xl footerbg-img">
          <div className="center align-items-center text-white flex-column">
            <h1 className="kagitingan mt-0">
              Are you a member of the RAD RUN CLUB?
            </h1>
            <PrimaryButton
              col={4}
              label={"Register For Rad Run"}
            ></PrimaryButton>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
