import { createSlice } from "@reduxjs/toolkit";

const loaderSlice = createSlice({
  name: "loader",
  initialState: { isLoading: false, isDataLoading: false },
  reducers: {
    showLoader: (state) => {
      return { isLoading: true };
    },
    hideLoader: (state) => {
      return { isLoading: false };
    },
    showDataLoader: (state) => {
      return { isDataLoading: true };
    },
    hideDataLoader: (state) => {
      return { isDataLoading: false };
    },
  },
});

export const { showLoader, hideLoader, showDataLoader, hideDataLoader } =
  loaderSlice.actions;

export default loaderSlice.reducer;
