import { Skeleton } from "primereact/skeleton";
import React from "react";

export default function CustomSkelton({
  width,
  height,
  className,
  shape,
  size,
}) {
  return (
    <Skeleton
      width={width ? width : ""}
      height={height ? height : ""}
      className={`mb-2 ${className}`}
      shape={shape}
      size={size}
      borderRadius="8px"
    ></Skeleton>
  );
}
