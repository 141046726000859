import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
    const isLoading = useSelector((state) => state.loader.isLoading);

    return (
        <div className="relative" style={{ display: isLoading ? "block" : "none" }}>
            <div
            className="flex align-items-center justify-content-center"
                style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#ffffff",
                    opacity: 0.6,
                    zIndex: 3000,
                }}
            >
            <div><i className="pi pi-spin pi-spinner font-bold"  style={{ fontSize: '3rem' }}/></div>
            {/* <div className="pacman">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/> */}
            {/* </div> */}
            </div>
        </div>
    );
};
export default Loader;
