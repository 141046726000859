import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, multipartApi } from "../../services/api";
import Constants from "../../services/constants";
import { authenticate } from "../../services/auth";
import { convertToFormdata } from "../../utils/commonFunctions";
import {
  hideDataLoader,
  hideLoader,
  showDataLoader,
  showLoader,
} from "./loaderSlice";
import { showToast } from "./toastSlice";

const initialState = {
  data: null,
  // loading: false,
  // error: null,
};

export const loginAction = createAsyncThunk(
  "user/login",
  async ({ data, navigate }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("post", Constants.END_POINT.LOGIN, data);
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(loginSlice.actions.setUserDetail(res.data));
        authenticate(res.data.token, () => navigate("/dashboard"));
        // navigate("/dashboard")
        localStorage.setItem("role", JSON.stringify(res.data.role));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);
export const profileAction = createAsyncThunk(
  "user/login",
  async ({ data = null }, { dispatch, getState }) => {
    try {
      dispatch(showDataLoader());
      const res = await api("get", Constants.END_POINT.MY_PROFILE);
      if (res.success && res.data) {
        // dispatch(showToast({ severity: "success", summary: res.message }));
        dispatch(loginSlice.actions.setUserDetail(res.data));
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      return Promise.reject("An error occurred.");
    } finally {
      dispatch(hideDataLoader());
    }
  }
);

export const editProfile = createAsyncThunk(
  "auth/sign-up",
  async ({ data, setVisible, setLoading }, { dispatch }) => {
    try {
      dispatch(showLoader());
      const payload = {
        ...data,
      };
      const res = await api("put", Constants.END_POINT.MY_PROFILE, payload);
      if (res.success) {
        dispatch(
          showToast({ severity: "success", summary: res.message, detail: "" })
        );
        dispatch(profileAction({}));
        setVisible(false);
        if (setLoading) setLoading(false);
        return res.data;
      } else {
        dispatch(
          showToast({ severity: "error", summary: res.message, detail: "" })
        );
        return Promise.reject(new Error("Request was not successful"));
      }
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    } finally {
      dispatch(hideLoader());
    }
  }
);

export const updatePasswordAction = createAsyncThunk(
  "user/update_password",
  async ({ data, setDialog, setData }, { dispatch, getState }) => {
    try {
      dispatch(showLoader());
      const payload = {
        oldPassword: data?.oldPassword,
        newPassword: data?.password,
      };

      const res = await api(
        "post",
        Constants.END_POINT.UPDATE_PASSWORD,
        payload
      );
      if (res.success) {
        dispatch(showToast({ severity: "success", summary: res.message }));
        setDialog({ visible: false });
        setData({
          oldPassword: "",
          password: "",
          confirmPassword: "",
        });
        return res.data;
      } else {
        dispatch(showToast({ severity: "error", summary: res.message }));
        setDialog({ visible: false });
        return Promise.reject(res.message);
      }
    } catch (error) {
      dispatch(showToast({ severity: "error", summary: error.message }));
      setDialog({ visible: false });
      return Promise.reject("An error occurred.");
    } finally {
      setDialog({ visible: false });
      dispatch(hideLoader());
    }
  }
);

const loginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetail: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setUserDetail } = loginSlice.actions;

export default loginSlice.reducer;
