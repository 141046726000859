import React from "react";
import PrimaryButton from "../buttons/button";
import { Dialog } from "primereact/dialog";
import Logo from "../../assets/images/logo-white.png";

const QRCard = ({ qrVisible, setQRVisible, qrImg, DownloadQRCode }) => {
  return (
    <Dialog
      visible={qrVisible}
      modal
      style={{ width: "28rem" }}
      breakpoints={{ "960px": "75vw", "641px": "98vw" }}
      header={"QR CODE"}
      className=" p-0 border-round-xl"
      onHide={() => setQRVisible(false)}
      content={({ hide }) => (
        <div className=" border-round-xl bg-white p-3">
          <div className="flex justify-content-end">
            <span onClick={() => setQRVisible(false)}>
              <i className="pi pi-times font-semibold cursor-pointer"></i>
            </span>
          </div>
          <div className="flex flex-column align-items-center justify-content-center">
            <div id="print" className="flex gap-2">
              <div className="flex flex-column justify-content-between">
                <img src={Logo} alt="" width={200} className="p-4" />
                <div>
                  <h2 className="kagitingan text-center w-full">
                    {qrImg?.radRunIdentifier}
                  </h2>{" "}
                  <h3 className="kagitingan text-center w-full">
                    {qrImg?.guardian?.phone}
                  </h3>{" "}
                </div>
              </div>
              <div className="w-full justify-content-center">
                <h2 className="kagitingan text-center w-full">
                  {qrImg?.firstName}
                </h2>{" "}
                <div>
                  <img src={qrImg?.qrCode} alt="" />
                </div>{" "}
              </div>
            </div>
            <PrimaryButton
              onClick={() => DownloadQRCode()}
              label={"Download"}
            ></PrimaryButton>
          </div>
        </div>
      )}
    ></Dialog>
  );
};

export default QRCard;
