import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table/table";
import Topbar from "../../../layout/Topbar";
import { Footer } from "../../../components/Footer/Footer";
import { ReactComponent as Empty } from "../../../assets/svg/empty.svg";
import ContentCard from "../../../components/cards/ContentCard";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDebounce } from "primereact/hooks";
import { getAllEvents } from "../../../redux1/Slices/eventSlice";
import moment from "moment";

export const AllEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);

  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);

  const [filterData, setFilterData] = useState({
    searchKeys: JSON.stringify(["name"]),
    searchString: "",
    country: "",
    state: "",
    city: "",
    date: "",
  });

  useEffect(() => {
    dispatch(
      getAllEvents({
        filterData,
        setEvents,
      })
    );
  }, [filterData]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <Empty />
        <h2 className="inter">Not Found</h2>
      </div>
    );
  };

  const eventName = (row) => {
    return (
      <span
        className="pink text-base cursor-pointer"
        onClick={() => navigate("/event-details/" + row._id)}
      >
        {row.name}
      </span>
    );
  };

  const countryName = (row) => {
    const splitAddress = row?.address?.name?.split(",");
    return <span className="text-base">{row?.country}</span>;
  };

  const stateName = (row) => {
    const splitAddress = row?.address?.name?.split(",");
    return <span className="text-base">{row?.state}</span>;
  };

  const EventColumns = [
    {
      field: "eventName",
      header: "Event Name",
      body: eventName,
    },
    {
      field: "country",
      header: "Country",
      body: countryName,
      sortable: true,
    },
    { field: "state", header: "State", body: stateName, sortable: true },
    { field: "city", header: "City", sortable: true },
  ];

  return (
    <>
      <Topbar></Topbar>
      <div className="content-box border-round-xl px-3">
        <h1 className="lg:text-5xl text-xl kagitingan my-3">Latest Results</h1>
        <div
          className="shadow-2 border-round-xl"
          style={{ minHeight: "30rem" }}
        >
          <ContentCard
            heading={
              filterData?.date
                ? moment(filterData.date).format("DD-MM-YYYY")
                : "Last 7 Days"
            }
            headingSize={"text-2xl"}
            inputSearch={inputSearch}
            placeholder={"by Event"}
            setSearch={setSearchValue}
            filterData={filterData}
            setFilterData={setFilterData}
            Eventfilter
            calendarFiler
            showButtonBar={true}
          >
            <CustomTable
              columns={EventColumns}
              emptyMessage={emptyMessage}
              data={events}
            />
          </ContentCard>
        </div>
      </div>
      <div className="h-12rem"></div>
      <Footer></Footer>
    </>
  );
};
