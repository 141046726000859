import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../utils/commonFunctions";
import { useSelector } from "react-redux";
import { Skeleton } from "primereact/skeleton";
import QRCODE from "../../assets/icon/qr_code.png";
import moment from "moment";
import { useState } from "react";
import { Paginator } from "primereact/paginator";
import { DashboardFun } from "../../view/common/DashboardFun";

export default function CustomTable({
  data,
  columns,
  view,
  edit,
  trash,
  qrCode,
  shareFunction,
  emptyMessage,
  showLines = true,
  extraClass,
  count,
  queryPaarams,
  ...props
}) {
  const isLoading = useSelector((state) => state.loader.isDataLoading);
  const items = Array.from({ length: 3 }, (v, i) => i);
  const { Gender } = DashboardFun();

  const actionTemplate = (col) => {
    return (
      <>
        <div className="flex align-items-center">
          {view ? (
            <i
              className="pi pi-eye mr-3 cursor-pointer"
              onClick={() => view(col)}
              style={{ color: "grey" }}
            />
          ) : null}
          {shareFunction ? (
            <i
              className="pi pi-user mr-3 cursor-pointer"
              onClick={() => shareFunction(col)}
              style={{ color: "grey" }}
            />
          ) : null}
          {edit ? (
            <i
              className="pi pi-pencil mr-3 cursor-pointer"
              onClick={() => edit(col)}
              style={{ color: "green" }}
            />
          ) : null}
          {trash ? (
            <i
              className="pi pi-trash cursor-pointer"
              onClick={() => trash(col._id)}
              style={{ color: "red" }}
            />
          ) : null}
          {qrCode ? (
            <>
              <i className="ml-3 cursor-pointer" onClick={() => qrCode(col)}>
                <img
                  src={QRCODE}
                  alt=""
                  style={{ width: "22px", marginTop: "6px" }}
                />
              </i>
            </>
          ) : null}
        </div>
      </>
    );
  };
  const dobTemplate = (col, field) => {
    return <div> {moment(col[field.field]).format("DD/MM/YYYY")}</div>;
  };
  const genderTemplate = (col, field) => {
    return (
      <>
        <div
          className={`text-center border-round-2xl inline-block  ${
            col?.gender === "Male" || col?.gender === "Female" ? "px-5" : ""
          }`}
          style={{
            backgroundColor:
              col?.gender === "Male"
                ? "#EBFBFF"
                : col?.gender === "Female"
                ? "#FFE3F6"
                : "",
          }}
        >
          <h1
            className={`text-base font-normal  ${
              col?.gender === "Male"
                ? "skyblue"
                : col?.gender === "Female"
                ? "pink"
                : ""
            }`}
          >
            {Gender.find((item) => item.value === col?.gender)?.name}
          </h1>
        </div>
      </>
    );
  };
  // const addressTemplate = (col, field) => {
  //   return (
  //     <div>
  //       {col?.country?.name},{col?.state?.name},{col?.city}
  //     </div>
  //   );
  // };
  const dateandtimeTemplate = (col, field) => {
    return <div>{moment(col?.createdAt).format("DD-MM-YYYY hh:m")}</div>;
  };

  const dateTemplate = (col, field) => {
    return <div>{moment(col?.date).format("DD-MM-YYYY")}</div>;
  };

  const stausTemplate = (e, field) => {
    if (
      moment(e?.date).utc().startOf("day").isSame(moment().utc().startOf("day"))
    )
      return <span className="text-green-300">Today</span>;
    else if (moment(e?.date).isBefore())
      return <span className="text-pink-300">Past</span>;
    else return <span className="text-yellow-300">Upcoming</span>;
  };

  const [first, setFirst] = useState(0);

  const onPageChange = (event) => {
    setFirst(event.first);
  };

  return (
    <div className="">
      <DataTable
        value={isLoading ? items : data}
        resizableColumns
        emptyMessage={emptyMessage ? () => emptyMessage() : "No Data Available"}
        className={!showLines ? "hide-table-lines " : " " + extraClass}
        tableStyle={{ minWidth: "100%" }}
        sortMode="multiple"
        paginator={true}
        rows={10}
      >
        {columns?.map((col, i) => (
          <Column
            key={col.field}
            field={col.field}
            header={col.header}
            body={
              isLoading ? (
                <Skeleton />
              ) : col.body === "ACTION" ? (
                actionTemplate
              ) : col.body === "GENDER" ? (
                genderTemplate
              ) : col.body === "DATEOFBIRTH" ? (
                dobTemplate
              ) : // ) : col.body === "ADDRESS" ? (
              //   addressTemplate
              col.body === "DATEANDTIME" ? (
                dateandtimeTemplate
              ) : col.body === "STATUSTEMPLATE" ? (
                stausTemplate
              ) : col.body === "DATE" ? (
                dateTemplate
              ) : (
                col.body
              )
            }
            style={{ ...col.style }}
            sortable={col.sortable}
          />
        ))}
      </DataTable>
      {/* <Paginator
        first={first}
        rows={10}
        totalRecords={data.length}
        rowsPerPageOptions={[10, 20, 30]}
        onPageChange={onPageChange}
      /> */}
    </div>
  );
}
