import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomSignInCard from "../../components/cards/CustomSignInCard";
import { isAuthenticated } from "../../services/auth";
import Topbar from "../../layout/Topbar";
import { loginAction } from "../../redux1/Slices/loginSlice";
import { showFormErrors } from "../../utils/commonFunctions";
import { allValidations } from "../../utils/formValidations";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleLogin = () => {
    if (showFormErrors(data, setData)) {
      dispatch(loginAction({ data, navigate }));
    }
  };
  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    }
  }, [navigate]);
  return (
    <>
      <Topbar />
      <div className="background-image">
        <div className="flex justify-content-center align-items-center w-full h-100-top">
          <CustomSignInCard
            eventorganizer
            heading={"SIGN IN"}
            signIn
            data={data}
            handleChange={handleChange}
            buttonLabel={"SIGN IN"}
            SubHeading={
              "Guess who's back, back again? Ready to get RAD? Just tap that SIGN IN button and let's get moving!."
            }
            checkbox
            submit={handleLogin}
          ></CustomSignInCard>
        </div>
      </div>
    </>
  );
};
export default Login;
