import React, { useEffect, useState } from "react";
import {
  CustomCalenderInput,
  CustomDropDown,
  CustomSearch,
} from "../input/input";
import PrimaryButton from "../buttons/button";
import ConfirmDailog from "../overlay/Dialog";
import CustomSkelton from "./CustomSkelton";
import { useSelector } from "react-redux";
import { City, Country, State } from "country-state-city";

const ContentCard = ({
  button,
  children,
  ClassName,
  extraClass,
  heading,
  label,
  dialog,
  setDialog,
  filter,
  Eventfilter,
  headingSize,
  yearFilter,
  calendarFiler,
  onClick,
  inputSearch,
  setSearch,
  inputSearchExtraClass,
  setFilterData,
  filterData,
  placeholder,
  showButtonBar,
  search = true,
}) => {
  const [showFilter, setshowFilter] = useState(false);
  const LoadingProfile = useSelector((state) => state.loader.isDataLoading);
  const [countries, setCountries] = useState(Country.getAllCountries());
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedState, setSelectedState] = useState({});
  const [selectedCity, setSelectedCity] = useState({});

  const onApplyFilter = () => {
    setFilterData((prev) => {
      return {
        ...prev,
        country: selectedCountry?.name,
        state: selectedState?.name,
        city: selectedCity?.name,
      };
    });
  };

  const onResetFilter = () => {
    setStates([]);
    setCities([]);
    setSelectedCountry({});
    setSelectedState({});
    setSelectedCity({});

    setFilterData((prev) => {
      return {
        ...prev,
        country: "",
        state: "",
        city: "",
      };
    });
  };

  let Filteritems = ({ display }) => {
    return (
      <div
        className={`absolute top-100 right-0 md:w-20rem shadow-1 border-1 border-400 border-round-xl mt-3 z-2 bg-white ${display}`}
      >
        <div className="">
          <div className="flex justify-content-between align-items-center p-2 pt-3">
            <h3 className="my-0 mx-2">Filter</h3>{" "}
            <i
              className="pi pi-times mx-2 cursor-pointer"
              onClick={() => setshowFilter(false)}
            ></i>
          </div>
          <hr style={{ borderColor: "rgb(225, 225, 225 ,0.3)" }} />
          <div className="p-2 pb-3">
            <h4 className="font-semibold my-2 mx-2">Country</h4>
            <CustomDropDown
              col={12}
              label={"Country"}
              options={countries.length && countries}
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.value);
                setStates(State.getStatesOfCountry(e?.value?.isoCode));
              }}
              optionLabel="name"
              name={"country"}
            ></CustomDropDown>
            <h4 className="font-semibold my-2 mx-2">State</h4>
            <CustomDropDown
              col={12}
              name={"state"}
              label={"State"}
              value={selectedState}
              options={states}
              optionLabel="name"
              onChange={(e) => {
                setSelectedState(e.value);
                setCities(
                  City.getCitiesOfState(
                    e?.value?.countryCode,
                    e?.value?.isoCode
                  )
                );
              }}
            ></CustomDropDown>
            <h4 className="font-semibold my-2 mx-2">City</h4>
            <CustomDropDown
              col={12}
              name={"city"}
              label={"City"}
              options={cities}
              value={selectedCity}
              optionLabel="name"
              onChange={(e) => {
                setSelectedCity(e.value);
              }}
            ></CustomDropDown>
            <div className="center gap-6 px-2">
              <PrimaryButton
                secondary
                label="Reset"
                onClick={onResetFilter}
              ></PrimaryButton>
              <PrimaryButton
                label="Apply"
                onClick={onApplyFilter}
              ></PrimaryButton>
            </div>{" "}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <ConfirmDailog dialog={dialog} setDialog={setDialog} />
      <div className={`"content-card content-box "${ClassName}`}>
        <div
          className={`flex md:flex-row justify-content-between lg:align-items-center flex-column-reverse gap-3 ${
            extraClass ? extraClass : "p-3"
          }`}
        >
          {LoadingProfile ? (
            <div className="flex justify-content-between w-full">
              <CustomSkelton width={300} height={40} />
              <div className="flex gap-4">
                <CustomSkelton width={300} height={40} />
                <CustomSkelton width={100} height={40} />
              </div>
            </div>
          ) : (
            <>
              <h1
                className={`kagitingan my-0 ${
                  headingSize ? headingSize : "lg:text-5xl text-xl "
                } `}
              >
                {heading}
              </h1>
              <div className="flex md:flex-row flex-column lg:col md:col col-12 align-items-center lg:gap-6 gap-3 m-0 p-0">
                <div className="center justify-content-end align-items-center gap-3 w-full">
                  {yearFilter ? (
                    <div className="flex align-items-center justify-content-end col-4 m-0 p-0 ">
                      <label htmlFor="">Year</label>
                      <CustomCalenderInput
                        view="year"
                        name="year"
                        value={filterData?.year}
                        dateFormat="yy"
                        inputClass={"m-0 p-0"}
                        extraClassName={"m-2 p-0"}
                        showButtonBar={showButtonBar}
                        data={filterData}
                        onChange={(e) =>
                          setFilterData({ ...filterData, year: e.value })
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {calendarFiler ? (
                    <div
                      className="custom-button-calendar"
                      style={{ width: "2px" }}
                    >
                      <CustomCalenderInput
                        name={"date"}
                        inputClass={"button-calendar"}
                        extraClassName={"mb-1"}
                        data={filterData}
                        showButtonBar={showButtonBar}
                        onChange={(e) =>
                          setFilterData({ ...filterData, date: e.value })
                        }
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {Eventfilter ? (
                    <div className="card flex justify-content-center relative lg:mr-4">
                      <span
                        className="bg-transparent text-gray-500 text-base border-gray-300 border-1 p-2 border-round-lg cursor-pointer"
                        onClick={() => setshowFilter((prev) => !prev)}
                      >
                        Filter
                        <i className="pi pi-align-left mx-2"></i>{" "}
                      </span>
                      <Filteritems display={showFilter ? "block" : "hidden"} />
                    </div>
                  ) : (
                    ""
                  )}
                  {search ? (
                    <div className="w-full md:w-auto">
                      <CustomSearch
                        value={inputSearch}
                        onChange={setSearch}
                        placeholder={"Search " + placeholder ?? ""}
                        inputClass={`lg:w-20rem md:w-11rem ${inputSearchExtraClass}`}
                      ></CustomSearch>
                    </div>
                  ) : (
                    ""
                  )}
                  {button ? (
                    <>
                      <PrimaryButton
                        col={2}
                        label={label}
                        onClick={onClick}
                      ></PrimaryButton>
                    </>
                  ) : (
                    ""
                  )}
                  {filter ? (
                    <div className="card flex justify-content-center relative ">
                      <span
                        className=" bg-transparent text-gray-500 text-base border-gray-300 border-1 p-2 border-round-lg cursor-pointer"
                        onClick={() => setshowFilter((prev) => !prev)}
                      >
                        Filter
                        <i className="pi pi-align-left mx-2"></i>{" "}
                      </span>
                      <Filteritems display={showFilter ? "block" : "hidden"} />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>{" "}
            </>
          )}
        </div>
        <div>{children}</div>
      </div>
    </>
  );
};

export default ContentCard;
