export const DashboardFun = () => {
  const childIdentify = ["Yes", "No", "Prefer not to say"];
  const Gender = [
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" },
    {
      name: "Indeterminant/Intersex/Unspecified",
      value: "Indeterminant/Intersex/Unspecified",
    },
    { name: "Prefer not to say", value: "Prefer not to say" },
  ];
  const options = [
    { name: "Male", value: "Male" },
    { name: "Female", value: "Female" },
  ];

  const restrictedAccessOptions = [
    "Physical - Mobility- requiring the use of a power wheelchair or scooter",
    "Physical - Mobility- requiring the use of a manual wheelchair",
    "Physical - Mobility- requiring the use of other mobility aids",
    "Physical - Mobility- no aid required but able to cover a limited distance or cannot manipulate objects",
    "Physical- not affecting mobility or dexterity",
    "Sensory - Blind or vision",
    "Sensory - Deaf or hearing",
    "Sensory & Developmental – Autism Spectrum Disorder",
    "Intellectual -  involving issues of speech, understanding, learning or retaining information",
    "Mental health",
    "Other (please specify)",
  ];

  return { childIdentify, Gender, options, restrictedAccessOptions };
};
