import React, { useEffect, useState } from "react";
import CustomTable from "../../../components/table/table";
import Topbar from "../../../layout/Topbar";
import { Footer } from "../../../components/Footer/Footer";
import { ReactComponent as Empty } from "../../../assets/svg/empty.svg";
import ContentCard from "../../../components/cards/ContentCard";
import { TableEventDetails } from "./TableEventDetails";
import { getAllStats } from "../../../redux1/Slices/radRunSlice";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "primereact/hooks";
export const Stats = () => {
  const dispatch = useDispatch();

  const { StatsColumns } = TableEventDetails();
  const allStats = useSelector((state) => state.radRun.allStats);
  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);

  const [filterData, setFilterData] = useState({
    searchKeys: JSON.stringify(["firstFinisher", "event.name"]),
    searchString: "",
    year: new Date(),
  });

  useEffect(() => {
    dispatch(
      getAllStats({
        filterData: {
          ...filterData,
          year: new Date(filterData.year).getFullYear().toString(),
        },
      })
    );
  }, [filterData]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <Empty />
        <h2 className="inter">Not Found</h2>
      </div>
    );
  };
  return (
    <>
      <Topbar></Topbar>
      <div className="content-box border-round-xl px-3">
        <h1 className="lg:text-5xl text-xl kagitingan my-3">Stats</h1>
        <div
          className="shadow-2 border-round-xl"
          style={{ minHeight: "30rem" }}
        >
          <ContentCard
            heading={"."}
            headingSize={"text-2xl text-white"}
            yearFilter
            inputSearch={inputSearch}
            setSearch={setSearchValue}
            setFilterData={setFilterData}
            filterData={filterData}
            placeholder={"by Event or Participent Name"}
          >
            <CustomTable
              columns={StatsColumns}
              emptyMessage={emptyMessage}
              data={allStats ? allStats : []}
              setFilterData={setFilterData}
            />
          </ContentCard>
        </div>
      </div>
      <div className="h-12rem"></div>
      <Footer></Footer>
    </>
  );
};
