import React, { useEffect, useState } from "react";
import { ReactComponent as Empty } from "../../assets/svg/empty.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useDebounce } from "primereact/hooks";

import { getMyEvents } from "../../redux1/Slices/eventSlice";
import CustomTable from "../../components/table/table";
import ContentCard from "../../components/cards/ContentCard";
import Topbar from "../../layout/Topbar";
import { Footer } from "../../components/Footer/Footer";
import moment from "moment";

export const MyEvents = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [events, setEvents] = useState([]);

  const [inputSearch, debouncedValue, setSearchValue] = useDebounce("", 800);

  const [filterData, setFilterData] = useState({
    searchKeys: JSON.stringify(["name"]),
    searchString: "",
    country: "",
    state: "",
    city: "",
    date: "",
  });

  useEffect(() => {
    dispatch(
      getMyEvents({
        filterData,
        setEvents,
      })
    );
  }, [filterData]);

  useEffect(() => {
    setFilterData({
      ...filterData,
      searchString: debouncedValue,
    });
  }, [debouncedValue]);

  const emptyMessage = () => {
    return (
      <div className="flex flex-column align-items-center justify-content-center pt-3 md:pt-8">
        <Empty />
        <h2 className="inter">Not Found</h2>
      </div>
    );
  };

  const eventName = (row) => {
    return (
      <span
        className="pink text-base cursor-pointer"
        onClick={() => navigate("/event-info/" + row._id)}
      >
        {row.name}
      </span>
    );
  };

  const countryName = (row) => {
    return <span className="text-base">{row?.country}</span>;
  };

  const stateName = (row) => {
    return <span className="text-base">{row?.state}</span>;
  };

  const EventColumns = [
    {
      field: "eventName",
      header: "Event Name",
      body: eventName,
    },
    {
      field: "country",
      header: "Country",
      body: countryName,
      sortable: true,
    },
    { field: "state", header: "State", body: stateName, sortable: true },
    { field: "city", header: "City", sortable: true },
    {
      field: "date",
      header: "Date",
      body: "DATE",
      sortable: true,
    },
    {
      field: "time",
      header: "Time",
      body: (e) => `${moment(e?.time).format("hh:mm A")} ${e?.timeZone ?? ""}`,
      sortable: true,
    },
    {
      field: "status",
      header: "Status",
      body: "STATUSTEMPLATE",
      sortable: true,
    },
  ];

  return (
    <>
      <Topbar></Topbar>
      <div className="content-box border-round-xl px-3">
        <h1 className="lg:text-5xl text-xl kagitingan my-3">My Events</h1>
        <div
          className="shadow-2 border-round-xl"
          style={{ minHeight: "30rem" }}
        >
          <ContentCard
            heading={"Events"}
            headingSize={"text-2xl"}
            inputSearch={inputSearch}
            placeholder={"by Event"}
            setSearch={setSearchValue}
            filterData={filterData}
            setFilterData={setFilterData}
            Eventfilter
            calendarFiler
            showButtonBar={true}
          >
            <CustomTable
              columns={EventColumns}
              emptyMessage={emptyMessage}
              data={events}
            />
          </ContentCard>
        </div>
      </div>
      <div className="h-12rem"></div>
      <Footer></Footer>
    </>
  );
};
