import React from "react";
import Success from "../../assets/images/Done.png";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../components/buttons/button";
import Topbar from "../../layout/Topbar";

const ResetSuccess = ({ userRole, subHeading, buttonLabel }) => {
  const navigate = useNavigate();
  return (
    <>
      <Topbar />
      <div className="background-image">
        <div className="flex justify-content-center align-items-center w-full h-100-top">
          <div className="signup md:col-4 col-11 p-4">
            <div className="text-center text-4xl pb-3 pb-4">
              <div>
                <img src={Success} alt="" width={106} />
              </div>
              <span className="kagitingan font-bold ">Congratulations</span>
              <div className="text-sm flex justify-content-center  subheading mt-2 ">
                <small className="lg:w-6">
                  Your password has been successfully reset. Click below to log
                  in magically.
                </small>
              </div>
            </div>
            <div className="center align-items-center flex-column ">
              <PrimaryButton
                col={5}
                extraClass={""}
                label="back to login"
                onClick={() => navigate("/login")}
              ></PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetSuccess;
