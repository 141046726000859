import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showToast: {},
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.showToast = action.payload;
    },
    hideToast: (state) => {
      state.showToast = {};
    },
  },
});

export const { showToast, hideToast } = toastSlice.actions;

export default toastSlice.reducer;
