import React, { useEffect, useState } from "react";
import {
  CustomCheckbox,
  CustomDropDown,
  CustomFloatInput,
  CustomFloatInputNumber,
  CustomFloatPassword,
  CustomInputphone,
  CustomMultiSelect,
} from "../input/input";
import PrimaryButton from "../buttons/button";
import { Link, useNavigate } from "react-router-dom";
import { allValidations } from "../../utils/formValidations";
import { showFormErrors } from "../../utils/commonFunctions";
import {
  AddClubAction,
  hideAddSchoolModal,
  registrationContext,
} from "../../redux1/Slices/registerSlice";
import { useDispatch, useSelector } from "react-redux";
import { purposeOfImplementionOptions } from "../../utils/constants";
import { Country, State, City } from "country-state-city";
import {
  patchClubModalDdata,
  updateClubAction,
} from "../../redux1/Slices/parentSlice";

const AddSchoolCard = ({ formData }) => {
  //   const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.data);
  const [state, setState] = useState();
  const [country, setCountry] = useState(Country.getAllCountries());
  const [isoCode, setIsoCode] = useState("au");
  const [laoding, setLoading] = useState(false);

  const clubDetails = useSelector((state) => state.children.addClubModalData);

  const clubTypeOptions = [
    {
      name: "School",
      value: "SCHOOL",
    },
    {
      name: "Club",
      value: "CLUB",
    },
  ];

  const [data, setData] = useState({
    id: "",
    type: "",
    name: "",
    contactEmail: "",
    phone: "+61",
    postCode: null,
    address: "",
    state: "",
    country: "",
    website: "",
    position: "",
    membersCount: "",
    pupropseOfImplementation: "",
  });

  useEffect(() => {
    if (clubDetails) {
      if (clubDetails.country) {
        setState(State.getStatesOfCountry(clubDetails.country.isoCode));
      }
      if (clubDetails.id) {
        setData({
          id: clubDetails.id,
          name: clubDetails.name,
          type: clubDetails.type,
          contactEmail: clubDetails.contactEmail,
          phone: clubDetails.phone,
          postCode: clubDetails.postCode,
          address: clubDetails.address,
          state: clubDetails.state,
          country: clubDetails.country,
          city: clubDetails.city,
          website: clubDetails.website,
          position: clubDetails.position,
          membersCount: clubDetails.membersCount,
          pupropseOfImplementation: clubDetails.pupropseOfImplementation,
        });
      }
    }
  }, []);

  const handleChange = ({ name, value }) => {
    const formErrors = allValidations(name, value, data);
    if (name === "country") {
      setIsoCode(value.isoCode);
      // const formErrors = allValidations("phone", "", data);
      setData({ ...data, [name]: value, phone: "", formErrors });
      setState(State.getStatesOfCountry(value.isoCode));
    } else setData((prev) => ({ ...prev, [name]: value, formErrors }));
  };

  const handleAddClub = () => {
    const ignore = ["id", "state"];
    if (showFormErrors(data, setData, ignore)) {
      setLoading(true);
      if (data.id) {
        dispatch(
          updateClubAction({
            data,
            resetClub,
          })
        );
      } else {
        dispatch(
          AddClubAction({
            data,
            resetClub,
          })
        );
      }
    }
  };

  const resetClub = () => {
    setData({
      id: null,
      name: "",
      type: "",
      contactEmail: "",
      phone: "",
      postCode: 0,
      address: "",
      state: "",
      country: "",
      website: "",
      position: "",
      membersCount: "",
      pupropseOfImplementation: "",
    });
    setLoading(false);
  };

  return (
    <div className="signup p-5 overflow-auto">
      <div className="mb-5">
        <div className="flex justify-content-between lg:text-5xl text-xl font-bold kagitingan">
          {"Register your School or Club"}
          <i
            className="pi pi-times cursor-pointer"
            onClick={() => {
              dispatch(patchClubModalDdata({}));
              dispatch(hideAddSchoolModal());
            }}
          ></i>
        </div>
        <p className=" text-600">
          {
            "Once your registration has been approved, you will gain access to our RADMIN HUB. The RADMIN HUB provides support, resources and tools to help you promote RAD RUN CLUB in your community."
          }
        </p>
        <h5 className="text-base font-medium m-0 mb-2">Please note:</h5>
        <p className="text-600">
          If your school or club is already registered with RAD RUN CLUB and you
          need to change or update details, please{" "}
          <a
            className="skyblue"
            href="http://hello@radrun.club"
            target="_blank"
          >
            contact us
          </a>
          .
        </p>
        <h5 className="text-base font-medium m-0 mb-2">RADMIN Details</h5>
        <p className="text-600 mb-2 mt-0">
          On approval, you will become the RADMIN for your school or club.{" "}
        </p>
        <p className="text-600 mb-2 mt-0">
          Your full name - {`${user?.firstName} ${user?.lastName}`}{" "}
        </p>
        <p className="text-600 mb-2 mt-0">Your email address - {user.email}</p>
      </div>
      <div className="grid">
        <CustomDropDown
          options={clubTypeOptions}
          optionLabel="name"
          optionValue="value"
          showFilter={false}
          data={data}
          col={6}
          label={"Type"}
          name="type"
          value={data?.type}
          onChange={handleChange}
        ></CustomDropDown>
        <CustomFloatInput
          name="position"
          value={data?.position}
          data={data}
          onChange={handleChange}
          col={6}
          label={"Position within your school or club"}
        ></CustomFloatInput>

        <CustomFloatInput
          name="name"
          value={data?.name}
          data={data}
          onChange={handleChange}
          col={6}
          label={"Name"}
        ></CustomFloatInput>

        <CustomDropDown
          options={country?.map((item, index) => {
            return { name: item.name, isoCode: item.isoCode };
          })}
          optionLabel="name"
          data={data}
          col={6}
          label={"Country"}
          name="country"
          value={data?.country}
          onChange={handleChange}
        ></CustomDropDown>

        <CustomDropDown
          options={state?.map((item, index) => {
            return {
              name: item.name,
              isoCode: item.isoCode,
              countryCode: item.countryCode,
            };
          })}
          optionLabel="name"
          data={data}
          disabled={!data?.country}
          col={6}
          label={"State"}
          name="state"
          value={data?.state}
          onChange={handleChange}
        ></CustomDropDown>

        <CustomFloatInput
          name="address"
          value={data?.address}
          data={data}
          onChange={handleChange}
          col={6}
          label={"Address"}
        ></CustomFloatInput>

        <CustomFloatInput
          name="city"
          value={data?.city}
          data={data}
          onChange={handleChange}
          col={6}
          label={"City/Town"}
        ></CustomFloatInput>

        <CustomFloatInputNumber
          name="postCode"
          maxLength={4}
          data={data}
          value={data?.postCode}
          onChange={handleChange}
          col={6}
          label={
            <span>
              Postcode{" "}
              <span className="text-xs text-600">
                (*Please enter 4 numbers only)
              </span>
            </span>
          }
        ></CustomFloatInputNumber>

        <CustomFloatInput
          name="contactEmail"
          value={data?.contactEmail}
          data={data}
          onChange={handleChange}
          label={"Contact Email"}
        ></CustomFloatInput>

        <CustomInputphone
          col={6}
          label={"Phone Number"}
          name="phone"
          isoCode={isoCode}
          value={data?.phone}
          data={data}
          onChange={handleChange}
        ></CustomInputphone>

        <CustomFloatInput
          name="website"
          data={data}
          value={data?.website}
          onChange={handleChange}
          label={"Website"}
        ></CustomFloatInput>

        <CustomFloatInput
          col={6}
          label={"How many students / members?"}
          name="membersCount"
          value={data?.membersCount}
          data={data}
          onChange={handleChange}
        ></CustomFloatInput>

        <CustomDropDown
          options={purposeOfImplementionOptions}
          optionLabel=""
          col={12}
          showFilter={false}
          label={
            "What is your main purpose or goal for implementing RAD RUN CLUB? (select one or more options)*"
          }
          name="pupropseOfImplementation"
          value={data.pupropseOfImplementation}
          data={data}
          onChange={handleChange}
        ></CustomDropDown>
      </div>
      <div className="center align-items-center flex-column">
        <PrimaryButton
          col={3}
          label={data?.id ? "Update" : "Add"}
          loading={laoding}
          onClick={handleAddClub}
        ></PrimaryButton>
      </div>
    </div>
  );
};

export default AddSchoolCard;
